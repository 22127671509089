import Module from "./Module";
import SimpleBar from 'simplebar';

export default class ModuleTicketsMenu extends Module {
	constructor(el) {
		super(el);
		
		$.extend(this.dom, {
			menu:          this.dom.el[0].querySelector('.menu'),
			menuTitle:     this.dom.el[0].querySelector('.menu__title'),
			menuScrollbar: this.dom.el[0].querySelector('.menu__scrollbar'),
			subMenu:       this.dom.el[0].querySelector('.menu__sub'),
			subMenuItems:  this.dom.el[0].querySelectorAll('.menu__sub li a')
		});
	}

	init() {
		new SimpleBar(this.dom.menuScrollbar);

		this.bindEvents();
		this.changeFocus();
	}

	bindEvents() {
	}

	subMenuHeightCalculation() {
		let subMenuHeight = this.dom.subMenu.clientHeight;
		
		let menuTitleHeight = $(this.dom.menuTitle).outerHeight(true);
		let allElements = subMenuHeight + menuTitleHeight;

		this.dom.subMenu.style.height = `${allElements}px`;
	}

	changeFocus() {
		let modal      = this.dom.el[0].offsetParent;
		let closeModal = modal.querySelector('.js-modal-close');
		let thisModule = this;

		$(document).on('modalOpen', () => {
			if(modal.classList.contains('is-open')) {
				setTimeout(() => {
					this.dom.subMenu.focus();
				}, 100);
			}
		});

		document.addEventListener('keydown', (event) => {
			if (modal.classList.contains('is-open')) {
				if (event.key == 'Tab' || event.keyCode == this.dom.keyboard.tab ) {
					if ( event.shiftKey ) { /* shift + tab */ 
						if (document.activeElement == this.dom.subMenuItems[0]) {
							closeModal.focus();
							event.preventDefault();
						}
					} else {
						if (document.activeElement == closeModal) {
							this.dom.subMenuItems[0].focus();
							event.preventDefault();
						}
					}
				}
			}
		});
		
		[].forEach.call(this.dom.subMenuItems, (element) => {
			element.addEventListener('keydown', (e) => {
				let prevEl, nextEl = null;
				let parentPrev = element.parentElement.previousElementSibling;
				let parentNext = element.parentElement.nextElementSibling;

				if (parentPrev) {
					prevEl = parentPrev.firstElementChild;
				} else {
					prevEl = thisModule.dom.subMenuItems[thisModule.dom.subMenuItems.length-1];
				}

				if (parentNext) {
					nextEl = parentNext.firstElementChild;
				} else {
					nextEl = thisModule.dom.subMenuItems[0];
				}

				if (event.keyCode == thisModule.dom.keyboard.arrowUp) {
					thisModule.subItemChangeFocus(e, prevEl);
				}

				if (event.keyCode == thisModule.dom.keyboard.arrowDown) {
					thisModule.subItemChangeFocus(e, nextEl);
				}
			});
		});
	}

	subItemChangeFocus(event, target) {    
        event.preventDefault();

        return target ? target.focus() : false;
    }
}