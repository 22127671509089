import Module from './Module';
import ModuleAutoScroll from './ModuleAutoScroll';

// TODO
//   [] Pass the error messages from a data-attribute so that webmasters could change them
//   [] Pass the error field type in the array to customize the position of the error
//   [] Refactor the ajax call to vanilla solution
//   [] Improve the phone validation to match the pattern of different countries
//   [] Implement the date validation
//   [] Convert the forEach to provide support to IE11

export default class FormValidator extends Module {

    constructor(el) {
        super(el);

        this.errors = [];
        this.validators = [];

        this.form = this.dom.el[0].querySelector('form');
        this.inputs = this.dom.el[0].querySelectorAll('input');
        this.originalTitle = document.title;
        this.entityMap = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#39;",
            "/": "&#x2F;",
            "`": "&#x60;",
            "=": "&#x3D;"
        };
        this.hasFileInputs = ($(this.form).find("input[type=file]").length > 0);
        this.formPosition = this.form.getBoundingClientRect().top;
        this.optionalLabel = this.dom.el[0].dataset.optionalLabel;
        //https://www.blueman.com/las-vegas/buy-tickets?formpreset=optin^1|firstname^frank
        this.presetArray = this.getPresetArray();
        this.countryFromCookie = this.checkForCookie();
        this.datagtm = this.dom.el[0].dataset.gtminfo;
    }

    init() {
        this.checkForCookie();
        this.handleUTMField();
        this.handleOptionals();
        this.handleLabels();
        this.handleSubmit();
        this.handleRegionSelect();
        this.prefillCountryField();
        this.handleFilesFields();
        this.handleA11yRadioFields();
        this.handleA11yFilesFields();
        this.ApplyFormPreset();
    }

    checkForCookie() {
        let countryInCookie = document.cookie.match(/^(?:.*\;\s?)?bmg-localization(?:\-fallback)?\=[^\;]*country\_code=([^\;\|]+)/i);
        if (countryInCookie && countryInCookie.length > 0) {
            return countryInCookie[1].toLowerCase();
        };
    }

    updateCTA(state) {
        let ctaBtn = this.form.querySelector('.cta');
        switch (state) {
            case 'processing':
                ctaBtn.innerHTML = `<span class="cta__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" >
                        <circle cx="8" cy="8" r="7.5"></circle>
                    </svg>
                    </span>
                    Processing`;
                ctaBtn.classList.add('cta__processing');
                break;
            case 'processed':
                ctaBtn.innerHTML = `<span class="cta__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 45.7 45.7"><path d="M20.69 38.33a5.3 5.3 0 0 1-7.5 0L1.54 26.7a5.3 5.3 0 1 1 7.5-7.5L16 26.13c.52.52 1.37.52 1.9 0L36.63 7.37a5.3 5.3 0 0 1 7.5 7.5L20.7 38.33z" fill="#FFF"/></svg>
                    </span>
                    Done`;
                ctaBtn.classList.remove('cta__processing');
                break;
            case 'error':
                ctaBtn.innerHTML = `<span class="cta__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 13.31 13.31"><path data-name="Tracé 24336" d="M11.6 13.02L6.67 8.07 1.7 13.02a1 1 0 0 1-1.42-1.41l4.95-4.95L.3 1.7A1 1 0 0 1 1.71.29l4.95 4.95L11.6.3a1 1 0 0 1 1.41 1.42L8.07 6.66l4.95 4.95a1 1 0 1 1-1.41 1.41z" fill="#FFF"/></svg>
                    </span>
                    Error`;
                ctaBtn.classList.remove('cta__processing');
                break;
            case 'submit':
                ctaBtn.innerHTML = ` <span>
                    Submit your application
                    </span>`;
                ctaBtn.classList.remove('cta__processing');
                break;
        }
    }

    ApplyFormPreset() {
        for (let x = 0; x < this.presetArray.length; x++) {
            let el = document.getElementById(this.presetArray[x].id);
            if (el) {
                if (el.type == "checkbox") {
                    el.checked = this.presetArray[x].value == '1';
                }
                else {
                    el.value = this.presetArray[x].value;
                }
            }
        }
    }

    getPresetArray() {
        let matchParam = window.location.href.match(/\?(?:.*\&)?formpreset\=([^\&\#]+)/i);
        if (matchParam && matchParam.length > 1) {
            let pArray = [];
            let fields = matchParam[1].split('|');
            for (let x = 0; x < fields.length; x++) {
                let f = fields[x].split('^');
                if (f.length > 1) {
                    pArray.push({
                        id: f[0],
                        value: f[1]
                    });
                }
            }
            return pArray;
        }
        return [];
    }

    getUTMParameter(theParameter) {
        let params = window.location.search.match('[^\w]' + theParameter + '\\=([^\&]+)');
        return (params && params.length > 1) ? decodeURIComponent(params[1]) : '';
    }

    handleUTMField() {
        let UTMCampaign = this.form.querySelector('input[id=UTMCampaign]');
        let UTMMedium = this.form.querySelector('input[id=UTMMedium]');
        let UTMSource = this.form.querySelector('input[id=UTMSource]');
        let UTMContent = this.form.querySelector('input[id=UTMContent]');
        let UTMTerm = this.form.querySelector('input[id=UTMTerm]');

        if (UTMCampaign) {
            UTMCampaign.value = this.getUTMParameter('utm_campaign')
        }
        if (UTMMedium) {
            UTMMedium.value = this.getUTMParameter('utm_medium')
        }
        if (UTMSource) {
            UTMSource.value = this.getUTMParameter('utm_source')
        }
        if (UTMContent) {
            UTMContent.value = this.getUTMParameter('utm_content')
        }
        if (UTMTerm) {
            UTMTerm.value = this.getUTMParameter('utm_term')
        }
    }

    handleOptionals() {
        let formFields = this.form.querySelectorAll('.form__field');
        for (var i = 0; i < formFields.length; i++) {
            let label = formFields[i].querySelector('.field__label');
            if (label) {
                let required = formFields[i].querySelector("[required]");
                !required ? label.innerText += ` ${this.optionalLabel}` : '';
            }
        }
    }

    handleLabels() {
        let labels = this.form.querySelectorAll('label');
        for (var i = 0; i < labels.length; i++) {
            var count = labels[i].innerText.length;
            var subLabel = labels[i].querySelector(".field__hint");
            if (count > 35 && subLabel) {
                subLabel.style.display = "inline-block";
            }
        }
    }

    addParam(p, url) {
        if (!url) return "";

        // remove if param exists
        var newParam = p.split('=');
        if (newParam.length > 0) {
            url = url.replace(new RegExp(newParam[0] + "\=[^\&]+"), '');
        }

        var m = url.match(/^([^\#]+\?)(.*)/i);
        if (m && m.length > 2) {
            return m[1] + p + '&' + m[2];
        }
        var pound = url.indexOf('#');
        if (pound == -1) {
            return url + "?" + p;
        } else {
            return url.substr(0, pound) + "?" + p + url.substr(pound);
        }
    }

    handleSubmit() {

        // Prevent the native validation but only if the JS is loaded
        this.form.setAttribute('novalidate', true);

        this.form.addEventListener('submit', e => {
            e.preventDefault();

            //Clean the errors messages
            this.resetSummaryPanel();
            this.removeInlineErrors();
            this.resetPageTitle();

            // If the field for formsource exist, set its value to the current page url (pathname)
            let frmUrl = this.form.querySelector('input[id=formUrl]');
            if (frmUrl && frmUrl.value === '') {
                frmUrl.value = location.protocol + "//" + location.hostname + location.pathname;
            }

            // Check all the validations
            let errorList = this.validateForm();

            // If needed prevent the submit then display all the errors
            if (errorList.length !== 0) {
                this.showSummary(errorList, this.formPosition);
                this.showInlineErrors(errorList);
                this.updatePageTitle(errorList);
                this.addGtm(errorList);
                let m = new ModuleAutoScroll($("main"));
                m.init();
                return false;
            }

            this.resetdropDowntoEnabled();
            this.updateCTA('processing');

            // var dataObj = $(this.form).serialize();
            var dataObj = (this.hasFileInputs) ? new FormData(this.form) : $(this.form).serialize();

            let ajaxOptions = {
                type: 'POST',
                url: this.form.getAttribute('action'),
                data: dataObj,
                contentType: false,
                processData: false,
                itemId: this.form.getAttribute('item'),
                cache: false
            };
            if (!this.hasFileInputs) {
                ajaxOptions.contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
            }

            // Make the API call
            $.ajax(ajaxOptions)
                .done((data, textStatus, jqXHR) => {
                    this.updateCTA('processed');
                    let hasConfirmationPage = this.dom.el[0].dataset.confirmationPage;
                    let confirmationMessage = this.dom.el[0].querySelector('.confirmationMessage');

                    let jData = JSON.parse(data);

                    if (jData.gtmData) {
                        if (jData.gtmData.pageGtm) {
                            if (jData.gtmData.groupGtm) {
                                jData.gtmData.pageGtm['group'] = jData.gtmData.groupGtm;
                            }
                        }
                        dataLayer.push(jData.gtmData.pageGtm);
                    }

                    if (hasConfirmationPage) {
                        // Leave some time for the tracking to fire
                        setTimeout(function () { window.location = hasConfirmationPage; }, 500);
                    }
                    else {
                        this.form.style.display = 'none';
                        confirmationMessage.style.display = 'block';
                    }
                })
                .fail((jqXHR, textStatus, errorThrown) => {
                    this.updateCTA('submit');
                    if (jqXHR.status == 400 && jqXHR.responseJSON != null) {
                        // this.dom.el[0].checkValidity();
                        /* var errorMessages = JSON.parse(jqXHR.responseJSON.Message);
    
                        if (errorMessages) {
                            {
                                for (var i = 0; i < errorMessages.length; i++) {                            
                                    window.dataLayer.push(errorMessages[i]);
                                }
                            }
                        }   */

                        let errorList = [];

                        if (typeof jqXHR.responseJSON.ModelState !== 'undefined') {
                            var errorMessages = jqXHR.responseJSON.ModelState;

                            for (var key in errorMessages) {
                                if (errorMessages.hasOwnProperty(key)) {
                                    if (key == "dataGtm") {
                                        var gtm = JSON.parse(errorMessages[key]);
                                        for (var i = 0; i < gtm.length; i++) {
                                            window.dataLayer.push(gtm[i]);
                                        }
                                    }
                                    else {
                                        var modelState = errorMessages[key];
                                        var fieldKey = key;
                                        if (key.indexOf("data.") > -1) {
                                            fieldKey = key.substring(5).charAt(0).toLowerCase() + key.substring(5).substring(1);
                                        }

                                        errorList.push({
                                            field: fieldKey,
                                            message: errorMessages[key],
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            var errorMessages = jqXHR.responseJSON;

                            for (var key in errorMessages) {
                                if (errorMessages.hasOwnProperty(key)) {
                                    errorList.push({
                                        field: key.charAt(0).toLowerCase() + key.substring(1),
                                        message: errorMessages[key],
                                    });
                                }
                            }
                        }

                        if (errorList.length !== 0) {
                            this.showSummary(errorList, this.formPosition);
                            this.showInlineErrors(errorList);
                            this.updatePageTitle(errorList);
                            let m = new ModuleAutoScroll($("main"));
                            m.init();
                            return false;
                        }

                    } else {
                        if (jqXHR.responseJSON) {
                            if (jqXHR.responseJSON.Message) {
                                this.showSummary([{
                                    field: null,
                                    message: jqXHR.responseJSON.Message,
                                }]);
                            }
                        }
                    }
                });

            return false;
        });
    }

    isValidZipPostalCode(zipValue, countryCode) {
        // Zip/Postal code is valid unless a specific country is selected and does not match regex.
        zipValue = zipValue.replace(/\s/i, '');
        if (countryCode) {
            if ((countryCode.toLowerCase() === "ca" && !zipValue.match(/^\w\d\w\d\w\d$/)) ||
                (countryCode.toLowerCase() === "us" && !zipValue.match(/^\d{5}(?:-\d{4})?$/i))) {
                return false;
            }
        }
        return true;
    }

    selectedCountry() {
        let countryField = this.form.querySelector("#country");
        if (countryField) {
            return countryField.options[countryField.selectedIndex].value;
        } else return "no country field in this form";
    }

    prefillCountryField() {
        if (this.countryFromCookie) {
            let countryField = this.form.querySelector('#country')
            for (let i = 0; i < countryField.length; ++i) {
                if (countryField.options[i].value === this.countryFromCookie) {
                    countryField.value = this.countryFromCookie;
                    countryField.dispatchEvent(new Event('change'));
                    break;
                }
            }
        }
    }

    setMessage(obj, lbl) {
        return eval('`' + obj.dataset.message + '`');
    }

    validateForm() {
        // Will loop over all the inputs, selects and textareas to run the appropriate text
        let errorList = [];
        let radioName = [];

        let countrySelected = this.selectedCountry();

        let requiredItems = this.form.querySelectorAll("input, select, textarea");
        for (let i = 0; i < requiredItems.length; i++) {
            let item = requiredItems[i];
            let isTextOnly = item.dataset.textOnly == "true";
            let isZipCode = item.dataset.zipcodevalidationon;

            if (item.type === "text") {
                if (item.hasAttribute('required')) {
                    if (this.validateEmptyField(item.value)) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.message
                        });
                        continue;
                    }
                }
                if (isZipCode && !this.isValidZipPostalCode(item.value, document.getElementById(isZipCode).value)) {
                    errorList.push({
                        field: item.id,
                        message: item.dataset.messageformat
                    });
                } else if (!this.validateEmptyField(item.value) && (isTextOnly && !this.validateTextOnly(item.value))) {
                    errorList.push({
                        field: item.id,
                        message: item.dataset.message
                    });
                }
            }

            if (item.type === "email") {
                if (item.hasAttribute('required')) {
                    if (this.validateEmptyField(item.value)) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.message
                        });
                        continue;
                    }
                }
                if (!this.validateEmail(item.value)) {
                    errorList.push({
                        field: item.id,
                        message: item.dataset.messageformat
                    });
                }
            }

            if (item.type === "select-one") {
                if (item.hasAttribute('required')) {
                    if (item.selectedIndex === 0) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.message
                        });
                        continue;
                    }
                }
            }

            if (item.type === "number") {
                if (item.hasAttribute('required')) {
                    if (item.validity.valueMissing) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.message
                        });
                        continue;
                    }
                }
                if (!item.validity.valueMissing && item.validity.badInput) {
                    errorList.push({
                        field: item.id,
                        message: item.dataset.messageformat
                    });
                }
            }

            if (item.type === "tel") {
                if (item.hasAttribute('required')) {
                    if (item.validity.valueMissing) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.message
                        });
                        continue;
                    }
                }
                // We could run test matching a pattern depending of the country selected
                if (item.value.length !== 0 && !this.validatePhone(item.value, countrySelected)) {
                    errorList.push({
                        field: item.name,
                        message: item.dataset.messageformat
                    });
                }
            }

            if (item.type === "file") {
                // check if required
                if (item.hasAttribute('required')) {
                    if (item.files.length === 0) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.message
                        });
                        continue;
                    }
                }
                if (item.files.length > 0) {
                    if (!this.validateFileType(item.files[0].type, item.accept)) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.messageformat
                        });
                        continue;
                    }
                    if (item.files[0].size > parseInt(item.dataset.sizeCap)) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.messagefilesize
                        });
                    }
                }
            }

            if (item.type === "textarea") {
                if (item.hasAttribute('required')) {
                    if (item.textLength === 0) {
                        errorList.push({
                            field: item.id,
                            message: item.dataset.message
                        });
                        continue;
                    }
                }
                if (item.maxLength !== -1) {
                    if (item.textLength > item.maxLength) {
                        errorList.push({
                            field: item.id,
                            message: `Please limit the field ${item.labels[0].children[0].innerHTML.toLowerCase()} to ${item.maxLength} characters`,
                        });
                        continue;
                    }
                }
            }

            if (item.type === "date") {
                if (item.hasAttribute('required')) {
                    if (item.value === '') {
                        errorList.push({
                            field: item.id,
                            message: `Please select a date for ${item.labels[0].children[0].innerHTML.toLowerCase()}`,
                        });
                        continue;
                    }
                }
                // Need to validate the date
            }

            if (item.type === "url") {
                if (item.hasAttribute('required')) {
                    if (item.value === '') {
                        errorList.push({
                            field: item.id,
                            message: `Please enter your ${item.labels[0].children[0].innerHTML.toLowerCase()}`,
                        });
                        continue;
                    }
                }
                if (item.value !== '' && !this.validateURL(item.value)) {
                    errorList.push({
                        field: item.id,
                        message: `Please enter a valid URL for ${item.labels[0].children[0].innerHTML.toLowerCase()}`,
                    });
                }
            }

            if (item.type === "radio") {
                if (item.required) {
                    if (radioName.indexOf(item.name) > -1) {
                        continue;
                    } else {
                        radioName.push(item.name);
                        if (this.form.querySelectorAll(`input[name=${item.name}]:checked`).length === 0) {
                            errorList.push({
                                field: item.id,
                                message: `Please select your ${item.name}`,
                            });
                        }
                    }
                }
            }
        }

        return errorList;
    }

    escapeHtml(t) {
        return String(t).replace(/(<([^>]+)>)/ig, "");
    }

    validateEmptyField(content) {
        if (content.trim().length > 0) {
            return false;
        } else {
            return true;
        }
    }

    // This function will validate the files by comparing the mime types of the files
    // So in the input element we need a mime type in the accept field
    validateFileType(type, acceptedTypes) {
        const arrayAcceptedTypes = acceptedTypes.replace(/\s/g, "").split(',');
        let validated = false;
        for (var i = 0; i < arrayAcceptedTypes.length; i++) {
            let acceptedType = arrayAcceptedTypes[i];
            if (type === acceptedType) {
                validated = true;
            }
        }
        return validated;
    }

    validateNumberOnly(content) {
        let regexNumbersOnly = /^[0-9]+$/;
        return (regexNumbersOnly.test(content));
    }

    validateTextOnly(content) {
        let regexFirstLastName = /^[A-zÀ-ÖØ-öø-ÿ\s'-.]+$/;
        return (regexFirstLastName.test(content));
    }

    validateEmail(content) {
        return (content.indexOf('@') > -1 && content.indexOf('.') > -1);
    }

    validateURL(content) {
        let regexURL = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
        return (regexURL.test(content));
    }

    validatePhone(content, country) {
        let regexPhone;
        if (country.toLowerCase() === "ca" || country.toLowerCase() === "us") {
            regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        } else {
            regexPhone = /^[\+]?\d{3,20}$/;
        }
        return (regexPhone.test(content));
    }

    returnFileSize(number) {
        if (number < 1024) {
            return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
            return (number / 1048576).toFixed(1) + 'MB';
        }
    }

    removeInlineErrors() {
        let errors = this.form.querySelectorAll('.error');
        for (var i = 0; i < errors.length; i++) {
            let error = errors[i];
            error.parentNode.removeChild(error);
        }
        let invalids = this.form.querySelectorAll('.invalid');
        for (var j = 0; i < invalids.length; i++) {
            let invalid = invalids[i];
            invalid.setAttribute('aria-invalid', false);
            invalid.classList.remove('invalid');
        }
    }

    updatePageTitle(errorList) {
        document.title = "(" + errorList.length + " errors) - " + document.title;
    }

    resetPageTitle() {
        document.title = this.originalTitle
    }

    resetSummaryPanel() {
        this.dom.el[0].querySelector('.errorSummary').style.display = "none";
        this.dom.el[0].querySelector('.errorList').innerHTML = '';
    }

    showSummary(errorList, position) {
        let errorSummary = this.dom.el[0].querySelector('.errorSummary');
        errorSummary.style.display = "block";
        for (var i = 0; i < errorList.length; i++) {
            let error = errorList[i];
            this.createErrorLink(error.message, error.field);
        }
        // this.dom.el[0].querySelector('#errorSummary-heading').focus();
        window.scrollTo(0, position - 60);
        this.dom.el[0].querySelector('#errorSummary-heading').focus({ preventScroll: true });
    }

    showInlineErrors(errorList) {
        for (var i = 0; i < errorList.length; i++) {
            let error = errorList[i];
            this.createErrorMessage(error.field, error.message);
        }
    }

    resetdropDowntoEnabled() {
        if (this.dom.el[0].querySelector('#job') && this.dom.el[0].querySelector('#job').getAttribute("disabled") != null) {
            this.dom.el[0].querySelector('#job').removeAttribute('disabled');
        }
    }

    // Create a new <div class="error"> with the error message between the Label and the field
    createErrorMessage(field, message) {
        let newError = document.createElement('span');
        newError.className = "error";
        newError.innerHTML = message;
        let fieldPosition = this.dom.el[0].querySelector(`[for="${field}"]`);
        let fieldUpload = fieldPosition.querySelector('.field__upload');
        let fieldType = this.dom.el[0].querySelector(`[id="${field}"]`).type;

        if (fieldType === "radio") {
            let legend = fieldPosition.parentElement.parentElement.querySelector('legend');
            legend.appendChild(newError);
            fieldPosition.parentElement.classList.add('invalid');
        } else if (fieldPosition && fieldUpload) {
            fieldPosition.insertBefore(newError, fieldUpload)
            fieldUpload.classList.add('invalid');
        } else {
            fieldPosition && fieldPosition.appendChild(newError);
        }

        this.dom.el[0].querySelector(`#${field}`).classList.add('invalid');
        this.dom.el[0].querySelector(`#${field}`).setAttribute('aria-invalid', true);
    }

    // Create a new <li><a> with the error message linking to the field with error
    createErrorLink(message, field = null) {
        let newListItem = document.createElement('li');
        if (field != null) {
            let newLink = document.createElement('a');
            newLink.setAttribute("href", `#${field}`);
            newLink.innerHTML = message;
            newListItem.appendChild(newLink);
        }
        else {
            let newSpan = document.createElement('span');
            newSpan.innerHTML = message;
            newListItem.appendChild(newSpan);
        }

        this.dom.el[0].querySelector('.errorList').appendChild(newListItem);
    }

    handleRegionSelect() {
        let countrySelect = this.dom.el[0].querySelector('#country');
        let regionSelect = this.dom.el[0].querySelector('#region');
        let regionWrapper = regionSelect.parentElement;
        let regionLabel = regionWrapper.querySelector('.field__label');
        let originalOptions = this.dom.el[0].querySelector('#region').innerHTML;
        countrySelect.addEventListener('change', () => {
            let target = this.dom.el[0].querySelector('#region');
            target.innerHTML = originalOptions;
            let options = target.querySelectorAll('optgroup');
            let match = 0;
            for (var i = 0; i < options.length; i++) {
                let option = options[i];
                if (countrySelect.value === option.label) {
                    target.removeAttribute('disabled');
                    target.setAttribute('required', true);
                    target.setAttribute('aria-required', true);
                    let optgroup = target.querySelector(`optgroup[label=${option.label}]`)
                    optgroup.removeAttribute('hidden');
                    target.value = "";
                    regionWrapper.removeAttribute('style');
                    regionLabel.innerHTML = optgroup.dataset.label;
                    target.options[0].innerText = optgroup.dataset.placeholder;
                    match += 1;
                } else {
                    target.querySelector(`optgroup[label=${option.label}]`).outerHTML = "";
                }
            }
            if (match === 0) {
                target.setAttribute('disabled', true);
                target.setAttribute('aria-required', false);
                target.removeAttribute('required');
                target.value = "";
                regionWrapper.setAttribute('style', 'display: none;');
            }
        });
    }

    handleFilesFields() {
        let filesFields = this.dom.el[0].querySelectorAll('input[type="file"]');
        for (var i = 0; i < filesFields.length; i++) {
            let removeBtn = filesFields[i].parentElement.querySelector('.field__btn--remove');
            let targetSpan = filesFields[i].parentElement.querySelector('.field__info');
            let uploadBtn = filesFields[i].parentElement.querySelector('.field__btn--upload');

            filesFields[i].addEventListener('change', () => {
                if (filesFields[i].files.length !== 0) {
                    removeBtn.style.display = "flex";
                    uploadBtn.style.display = "none";
                } else {
                    removeBtn.style.display = "none";
                    uploadBtn.style.display = "flex";
                }
                this.updateFileSFieldMessage(field, targetSpan);
            });

            this.handleRemoveFilesBtns(filesFields[i], removeBtn, targetSpan);
        }
    };

    handleRemoveFilesBtns(input, button, target) {
        button.addEventListener('click', () => {
            input.value = "";
            this.updateFileSFieldMessage(input, target);
            var event = new Event('change');
            input.dispatchEvent(event);
        });
    };

    updateFileSFieldMessage(input, target) {
        if (input.files.length !== 0) {
            let fileSize = input.files[0].size;
            let fileName = input.files[0].name;
            let ellipsName = this.ellipsis(15, fileName);
            target.innerHTML = `${ellipsName} - ${this.returnFileSize(fileSize)}`;
        } else {
            target.innerHTML = `No file selected`;
        }
    }

    // Add keyboard functionality to the fields of type file
    handleA11yFilesFields() {
        let filesBtns = this.dom.el[0].querySelectorAll('.field__upload');
        for (var i = 0; i < filesBtns.length; i++) {
            let filesBtn = filesBtns[i];
            filesBtn.addEventListener('keypress', (e) => {
                if (e.keyCode === 32 || e.keyCode === 13) {
                    e.preventDefault();
                    let target = filesBtn.parentElement.nextElementSibling;
                    target.click();
                }
            });
        }
    }

    // Add keyboard functionality to the fields of type radio
    handleA11yRadioFields() {
        let radioLabels = this.dom.el[0].querySelectorAll('.radio__btn');
        for (var i = 0; i < radioLabels.length; i++) {
            let radioLabel = radioLabels[i];
            radioLabel.addEventListener('keypress', (e) => {
                if (e.keyCode === 32 || e.keyCode === 13) {
                    e.preventDefault();
                    radioLabel.previousElementSibling.checked = true;
                }
            });
        }
    }

    addGtm(errorList) {
        if (this.datagtm) {
            var errorMessages = [];

            for (var i = 0; i < errorList.length; i++) {
                errorMessages.push(errorList[i].message);
            }

            errorMessages = errorMessages.join('|');

            this.datagtm = this.datagtm.replace("%eventLabel%", errorMessages);
            var datagtmCleanJson = this.cleanJson(this.datagtm);

            window.dataLayer.push(datagtmCleanJson);
        }
    }

    cleanJson(strJson) {
        var s = strJson.replace(/\\n/g, "\\n")
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, "\\&")
            .replace(/\\r/g, "\\r")
            .replace(/\\t/g, "\\t")
            .replace(/\\b/g, "\\b")
            .replace(/\\f/g, "\\f");

        // remove non-printable and other non-valid JSON chars
        s = s.replace(/[\u0000-\u0019]+/g, "");
        return JSON.parse(s);
    }


    ellipsis(length, el) {
        if (el.length > length) {
            for (let i = length; i > 0; i--) {
                if ((el.charAt(i - 1) != ',' || el.charAt(i - 1) != '.' || el.charAt(i - 1) != ';')) {
                    return el.substr(0, i) + (el.length > length ? '...' : '');
                }
            }
        } else {
            return el;
        }
    }
}