import Module from './Module';

export default class ModuleCarouselHighlights extends Module {
    
    constructor(el) {
        super(el);
        $.extend(this.dom, {
            carousel:      this.dom.el.find('.carousel__items'),
            carouselItems: this.dom.el[0].querySelectorAll('.carousel__item')
		});
		
		this.settings = {
			accessibility: true,
            infinite: false,
			arrows: false,
            dots: true,
            initialSlide: 1,
            centerMode: true,
            centerPadding: '15px',
            cssEase: 'linear',
            mobileFirst: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            variableWidth: true,
            focusOnSelect: true,
            responsive: [
                {
					breakpoint: this.dom.breakpoint.sm,
					settings: {
                        centerPadding: '80px',
                        variableWidth: true
                	}
                },
                {
					breakpoint: this.dom.breakpoint.md,
					settings: {
                        centerPadding: '120px',
                        variableWidth: true
                	}
                },
                {
					breakpoint: this.dom.breakpoint.lg,
					settings: {
                        centerPadding: '193px',
                        variableWidth: true
                	}
                }
            ]
		}
    }

    init() {
        if (this.dom.carouselItems.length >= 5) {
            this.settings.initialSlide = 2;
        }

        this.dom.carousel.slick(this.settings);

        let dotsContainer = this.dom.el[0].querySelector('.slick-dots');
            
        if(dotsContainer){
            dotsContainer.setAttribute('role','presentation');

            let dots = this.dom.el[0].querySelectorAll('.slick-dots button');

            [].forEach.call(dots, function(element) {
                element.setAttribute('tabindex','-1');
            });
        }
    }
}
