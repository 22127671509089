import Module from './Module';

export default class ModuleButtonCarousel extends Module {
    
    constructor(el) {
        super(el);
        $.extend(this.dom, {
            carousel: this.dom.el.find('.button-list__items')
		});
		
		this.settings = {
            variableWidth: true,
            accessibility: false,
            infinite: false,
			arrows: false,
            dots: false,
            slidesToScroll: 1,
            slidesToShow: 1,
            cssEase: 'linear',
            mobileFirst: true,
            rows: 0
		}
    }

    init() {
        this.slickInit();
        this.bindEvents();
    }

    bindEvents() {
        window.addEventListener('resize', () => {
            this.slickInit();
        });
    }

    slickInit() {
        let listItems = this.dom.el[0].querySelectorAll('.button-list__item');

        let arr = [];
        for (let i = 0; i < listItems.length; i++ ) {
            arr.push(listItems[i].offsetWidth);
        }
        let allItemsWidth = _.sum(arr); // Add container spacing

        if (allItemsWidth >= window.innerWidth) {
            if (!this.dom.carousel[0].classList.contains('slick-initialized')) {
                this.dom.el[0].classList.add('slicked');
                this.dom.carousel.slick(this.settings);
            }
        } else {
            if (this.dom.carousel[0].classList.contains('slick-initialized')) {
                this.dom.el[0].classList.remove('slicked');
                this.dom.carousel.slick('unslick');
            }
        }
    }

}
