import Module from './Module';

export default class ModuleAutoScroll extends Module {
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            scrollingLinks: this.dom.el[0].querySelectorAll('a[href^="#"]')
        });
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {
        let thisModule = this;

        if (!this.dom.scrollingLinks) {
            return;
        }

        [].forEach.call(this.dom.scrollingLinks, (element) => {
            let target = document.getElementById(element.hash.substring(1));

            if (target) {
                element.addEventListener('click', (e) => {
                    if(getComputedStyle(target, null).display === "none" || target.nodeName === "INPUT" || target.nodeName === "SELECT") {
                        thisModule.doScrolling(thisModule.getElementYPosition(target.parentElement) - 90, 200);
                    } else {
                        thisModule.doScrolling(thisModule.getElementYPosition(target) - 60, 500);
                    }
                });
            }
        });
    }

    getElementYPosition(element) {
        var y = element.offsetTop;
        var node = element;

        while (node.offsetParent && node.offsetParent != document.body) {
            node = node.offsetParent;
            y += node.offsetTop;
        }

        return y;
    }

    doScrolling(elementY, duration) {
        var startingY = window.pageYOffset;
        var diff = elementY - startingY;
        var start;

        window.requestAnimationFrame(function step(timestamp) {
            if (!start) start = timestamp;
            var time = timestamp - start;
            var percent = Math.min(time / duration, 1);

            window.scrollTo(0, startingY + diff * percent);

            if (time < duration) {
                window.requestAnimationFrame(step);
            }
        })
    };
}