import Module from './Module';

export default class ModuleAccordion extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            toggleAccordionButtons: this.dom.el[0].querySelectorAll('.js-accordion__tab-title'),
            allTabContents:         this.dom.el[0].querySelectorAll('.js-accordion__content'),
            defaultTabOpened:       this.dom.el[0].querySelector('.js-accordion__content.is-open')

        });
    }

    init() {
        this.bindEvents();

        if(this.dom.defaultTabOpened) {
            this.setTabHeight(this.dom.defaultTabOpened);
        }        
    } 

	bindEvents() {
        let thisModule = this;
 
        [].forEach.call(this.dom.toggleAccordionButtons, (element) => {
            element.addEventListener('click', (e) => {
                thisModule.toggleDropdown(element);
            });

            element.addEventListener('keydown', (e) => {
                if (e.keyCode == thisModule.dom.keyboard.enter || e.keyCode == thisModule.dom.keyboard.spacebar) {
                    e.preventDefault();
                    thisModule.toggleDropdown(element);
                }
            });
        });
        
        if(location.hash) {
            let hash = location.hash.toLowerCase();
            if(this.dom.el[0].querySelector(hash) !== null){
                let id = this.dom.el[0].querySelector(hash);

                if(this.dom.el[0].querySelector(hash).classList.contains("accordion__tab")){
                    let tab = id.querySelector(".accordion__tab-title");
                    thisModule.toggleDropdown(tab);

                }else{
                    let tab = id.parentElement.parentElement.querySelector(".accordion__tab-title");
                    thisModule.toggleDropdown(tab);
                }                
            }
        }
    }

    closeTabs(element) {
        element.parentElement.classList.remove('is-open');
        element.parentElement.querySelector('.js-accordion__tab-title').setAttribute('aria-expanded','false');
        element.setAttribute('aria-hidden','true');
        element.classList.remove('is-open');
        element.style.height = 0;
    }

    toggleDropdown(element) {
        let parentTab = element.parentElement.parentElement;
        let content = parentTab.querySelector('.js-accordion__content');
        
        if (!parentTab.classList.contains('is-open')) {

            parentTab.classList.add('is-open');
            content.classList.add('is-open');
            content.setAttribute('aria-hidden','false');
            this.setTabHeight(content);

            element.setAttribute('aria-expanded','true');
        } else {
            this.closeTabs(content);
        }
    }
    
    setTabHeight(element) {
        element.style.height = element.scrollHeight + 'px';
    }
}
