import Module from "./Module";

export default class ModuleLocations extends Module {
	constructor(el) {
		super(el);
	}

	init() {
		this.arrayLinks = this.dom.el[0].querySelectorAll('.locations__item a');

		this.bindEvents();
	}

	bindEvents() {
		Array.from(this.arrayLinks).map((link, index) => {
            link.addEventListener('focus', () => {
				link.parentElement.parentElement.classList.add("-hover");
			});
			
			link.addEventListener('blur', () => {
				link.parentElement.parentElement.classList.remove("-hover");
			})
        });
	}
}