import Module from './Module';

export default class ModuleCarousel extends Module {
    
    constructor(el) {
        super(el);
        $.extend(this.dom, {
            carousel:      this.dom.el.find('.carousel__items'),
            buttonWrapper: this.dom.el[0].querySelector('.carousel__btn-wrapper')
		});
		
		this.settings = {
            accessibility: false,
            infinite: false,
			arrows: false,
            dots: false,
            cssEase: 'linear',
            slidesToScroll: 1,
            slidesToShow: 2.09,
            mobileFirst: true,
            responsive: [
                {
					breakpoint: this.dom.breakpoint.xs - 1,
					settings: {
                        slidesToScroll: 3,
                        slidesToShow: 3.25,
                        arrows: true,
						appendArrows: this.dom.buttonWrapper
                	}
                },
                {
					breakpoint: this.dom.breakpoint.md - 1,
					settings: {
                        slidesToScroll: 4,
                        slidesToShow: 4.25,
                        arrows: true,
						appendArrows: this.dom.buttonWrapper
                	}
                },
                {
					breakpoint: this.dom.breakpoint.lg - 1,
					settings: {
                        slidesToScroll: 4,
                        slidesToShow: 4.25,
                        arrows: true,
						appendArrows: this.dom.buttonWrapper
                	}
                },
                {
					breakpoint: 1600,
					settings: {
                        slidesToScroll: 6,
                        slidesToShow: 6.25,
                        arrows: true,
						appendArrows: this.dom.buttonWrapper
                	}
                }
            ]
		}
    }

    init() {
        this.dom.carousel.slick(this.settings);
    
        if(this.dom.el[0].querySelector('.slick-next')) {
            if(this.dom.w.innerWidth >= this.dom.breakpoint.sm) {
                let prevButtonText = this.dom.el[0].querySelector('.slick-prev').innerHTML = this.dom.buttonWrapper.dataset.buttonPrev;
                let nextButtonText = this.dom.el[0].querySelector('.slick-next').innerHTML = this.dom.buttonWrapper.dataset.buttonNext;
            }
        }
    }

}
