import Module from './Module';

export default class ModuleButtonList extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            toggleButtonList:    this.dom.el[0].querySelector('.button-list__toggle'),
            buttonListMenu:      this.dom.el[0].querySelector('.button-list__nav'),
            buttonListMenuItems: this.dom.el[0].querySelectorAll('.button-list__cta')
		});
    }

    init() {
        this.bindEvents();
        
    }

	bindEvents() {
        let thisModule = this;

        function toggleButtonList(menu, menuToggle, target) {
            if(!menu.classList.contains('is-open')) {
                menuToggle.setAttribute('aria-expanded','true')
                menu.classList.toggle('is-open');
                menuToggle.classList.toggle('is-open');

                /*thisModule.openDirection();*/

                /*if (!thisModule.dom.el[0].classList.contains('+language')) {
                    let target = $(thisModule.dom.el[0]);
                    let scrollTarget = 100;

                    if (document.body.clientWidth >= 992 && document.body.clientWidth <= 1199) {
                        scrollTarget = 200;
                    } else if (document.body.clientWidth >= 1200) {
                        scrollTarget = 300;
                    }

                    $('html, body').animate({scrollTop: target.offset().top - scrollTarget}, 500, () => {});
                }*/
            } else {
                menuToggle.setAttribute('aria-expanded','false')
                menu.classList.toggle('is-open');
                menuToggle.classList.toggle('is-open');
            }
        }

        if (this.dom.toggleButtonList != null) {
            this.dom.toggleButtonList.addEventListener('click', (e) => {
                e.preventDefault();
                toggleButtonList(this.dom.buttonListMenu, this.dom.toggleButtonList, e);
            });
    
            this.dom.toggleButtonList.addEventListener('keyup', (e) => {
                e.preventDefault();
    
                if (e.keyCode == this.dom.keyboard.spacebar) {
                    toggleButtonList(this.dom.buttonListMenu, this.dom.toggleButtonList, e);
                }
            });
    
            this.dom.toggleButtonList.addEventListener('keydown', (e) => {
                let isDropdownOpen = this.dom.buttonListMenu.classList.contains('is-open');
    
                if (e.keyCode == this.dom.keyboard.arrowDown) {
                    e.preventDefault(); 
                    
                    if (isDropdownOpen) {
                        this.dom.buttonListMenuItems[0].focus();
                    }
                }
                
                if (e.keyCode == this.dom.keyboard.arrowUp) {
                    e.preventDefault();
    
                    if (isDropdownOpen) {
                        this.dom.buttonListMenuItems[this.dom.buttonListMenuItems.length-1].focus();
                    }
                }

               
            });
        }
                    
        [].forEach.call(this.dom.buttonListMenuItems, (element) => {
            element.addEventListener('keydown', (e) => {
                let prevEl, nextEl = null;
                let parentPrev = element.parentElement.previousElementSibling;
                let parentNext = element.parentElement.nextElementSibling;

                if (parentPrev) {
                    prevEl = parentPrev.firstElementChild;
                } else {
                    prevEl = thisModule.dom.buttonListMenuItems[thisModule.dom.buttonListMenuItems.length-1];
                }

                if (parentNext) {
                    nextEl = parentNext.firstElementChild;
                } else {
                    nextEl = thisModule.dom.buttonListMenuItems[0];
                }

                if (event.keyCode == thisModule.dom.keyboard.arrowUp) {
                    thisModule.changeFocus(e, prevEl);
                }

                if (event.keyCode == thisModule.dom.keyboard.arrowDown) {
                    thisModule.changeFocus(e, nextEl);
                }
            });
        });

        document.addEventListener('click', (e) => {
            if (thisModule.dom.buttonListMenu && thisModule.dom.buttonListMenu.classList.contains('is-open')) {
                if (!e.target.classList.contains('button-list__toggle')) {
                    thisModule.dom.toggleButtonList.setAttribute('aria-expanded','false');
                    thisModule.dom.buttonListMenu.classList.remove('is-open');
                    thisModule.dom.toggleButtonList.classList.remove('is-open');
                }
            }
        });

        document.addEventListener('keyup', (e) => {
            if (thisModule.dom.buttonListMenu && thisModule.dom.buttonListMenu.classList.contains('is-open')) {
                if (!e.target.classList.contains('button-list__cta') && !e.target.classList.contains('button-list__toggle')) {
                    thisModule.dom.toggleButtonList.setAttribute('aria-expanded','false');
                    thisModule.dom.buttonListMenu.classList.remove('is-open');
                    thisModule.dom.toggleButtonList.classList.remove('is-open');
                }
            }
        });

        if (this.dom.toggleButtonList != null) { 
            this.dom.toggleButtonList.addEventListener('keyup', (e) => {
                e.preventDefault();
            });
        }
    }
    
    changeFocus(event, target) {    
        event.preventDefault();

        return target ? target.focus() : this.dom.toggleButtonList.focus();
    }

    openDirection() {
        if (window.innerWidth <= 767) {
            this.dom.buttonListMenu.classList.remove('open-bottom');
            this.dom.buttonListMenu.classList.add('open-top');

            this.dom.toggleButtonList.classList.remove('open-bottom');
            this.dom.toggleButtonList.classList.add('open-top');
        } else {
            this.dom.buttonListMenu.classList.remove('open-top');
            this.dom.buttonListMenu.classList.add('open-bottom');
        }
    } 
}
