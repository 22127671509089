import Module from "./Module";

export default class ModuleVideo extends Module {
	constructor(el) {
		super(el);
		
		$.extend(this.dom, {
			source: this.dom.el[0].querySelector('source'), 
		});
	}

	init() {
		let video = this.dom.el[0],
		button = this.dom.el[0].parentElement.querySelector(".hero__video-btn"),
		vh = this.dom.el[0].offsetHeight,
		scroll = window.pageYOffset;

		this.updateVideoSource(this.dom.el[0]);
		this.bindEvents();
	
		window.addEventListener('wheel', function(e) {
			scroll = window.pageYOffset;

			if (e.deltaY > 0) {
				if(button && !video.paused && scroll > vh){
					button.classList.remove("paused");
					button.querySelector("span").textContent = video.dataset.play;
					video.pause();
				}
			}
		});
		
		if (button) {
			button.onclick = function () {
				if (video.paused) {
					button.classList.add("paused");
					button.querySelector("span").textContent = video.dataset.pause;
					video.play();
				} else {
					button.classList.remove("paused");
					button.querySelector("span").textContent = video.dataset.play;
					video.pause();
				}
	
				return false;
			};
		}
	}

	bindEvents() {
		this.dom.w.addEventListener('resize', () => {
			this.updateVideoSource(this.dom.el[0]);
		});
	}

	updateVideoSource(video) {
		if (this.dom.w.innerWidth >= this.dom.breakpoint.sm) {
			this.dom.source.setAttribute('src', this.dom.source.getAttribute('data-src'));

			if (video.readyState != 4 && this.dom.source.getAttribute('src') != null) {
				video.load();
				video.play();
			}
		}
	} 
}