import Module from "./Module";

export default class ModuleQuote extends Module {
	constructor(el) {
		super(el);
		
		$.extend(this.dom, {
			quote:   document.querySelector('.quote')
		});
	}

	init() {
		this.bindEvents();
	}

	bindEvents() {
		document.addEventListener('scroll', (e) => {
			if (this.dom.quote){
				this.dom.quote.style.display = "block";
			}
		});
	}
}