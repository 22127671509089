import Module from './Module';

export default class ModuleNewsletter extends Module {
	constructor(el) {
		super(el);
		
		$.extend(this.dom, {
			trigger: document.querySelector('.js-newsletter-trigger'),
			close:   this.dom.el[0].querySelector('.js-close'),
			itemId:  this.dom.el[0].dataset.itemid
		});
	}

	init() {
		this.bindEvents();
	}

	bindEvents() {
		if (this.dom.trigger && localStorage.getItem(this.dom.itemId) === null) {
			this.dom.el[0].classList.add('show');
		}

		this.dom.close.addEventListener('click', (e) => {
			e.preventDefault();
			this.dom.el[0].classList.remove('show');
			window.localStorage.setItem(this.dom.itemId, '0');
		});
	}
}