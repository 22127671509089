import Module from './Module';

export default class ModuleCarouselShowDetails extends Module {

    constructor(el) {
        super(el);
        $.extend(this.dom, {
            carousel:     this.dom.el.find('.carousel__items'),
            dotsWrapper:  this.dom.el[0].querySelector('.carousel__btn-dots'),
            dots:         this.dom.el[0].querySelectorAll('.carousel__btn-dot'),
            readMores:    this.dom.el[0].querySelectorAll('.read-more')
		});

		this.settings = {
			accessibility: false,
            infinite: false,
			arrows: false,
            dots: false,
            initialSlide: 0,
            centerMode: true,
            centerPadding: '20px',
            cssEase: 'linear',
            mobileFirst: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            focusOnSelect: true,
            appendDots: this.dom.dotsWrapper,
            responsive: [
                {
					breakpoint: this.dom.breakpoint.sm,
					settings: {
                        centerPadding: '60px',
                        variableWidth: false
                	}
                },
                {
					breakpoint: this.dom.breakpoint.md,
					settings: {
                        centerPadding: '120px',
                        variableWidth: false
                	}
                },
                {
					breakpoint: this.dom.breakpoint.lg,
					settings: {
                        centerPadding: '193px',
                        variableWidth: true
                	}
                }
            ]
		}
    }

    init() {
        this.dom.carousel.slick(this.settings);
        this.initCarousel(this.dom.carousel);

        this.activeReadMore(this.dom.carousel);
    }

    initCarousel() {
        let thisModule = this;

        this.dom.dots[this.settings.initialSlide].classList.add('is-active');

        [].forEach.call(this.dom.dots, (element, index) => {
            element.addEventListener('click', (event) => {
                thisModule.dom.carousel.slick('slickGoTo', index);

                thisModule.resetTabs();

                element.classList.add('is-active');
            });
        });

        this.dom.carousel.on('afterChange', (event, slick, currentSlide) => {
            thisModule.resetTabs(currentSlide);
            thisModule.dom.dots[currentSlide].classList.add('is-active');
            thisModule.activeReadMore();

            let currentSlideItem = thisModule.dom.carousel[0].querySelector('.slick-track .slick-slide:nth-child(' + (currentSlide + 1) + ') .carousel__item');
            //currentSlideItem.focus();
        });

        this.dom.carousel.on('swipe')
    }

    resetTabs(current) {
        let thisModule = this;
        let slides = this.dom.carousel[0].querySelectorAll('.slick-slide');

        [].forEach.call(slides, (element, index) => {
            element.setAttribute('aria-hidden', 'true');

            if(current != index){
                if (element.querySelector(".read-more") && element.querySelector(".read-more").getAttribute('aria-expanded') === "true"){
                    element.querySelector(".read-less").click();
                }
            }
        });

        [].forEach.call(thisModule.dom.dots, (element, index) => {
            element.classList.remove('is-active');
        });  
    }

    activeReadMore() {
        let thisModule = this;

        if (this.dom.readMores.length) {
            [].forEach.call(this.dom.readMores, (element, index) => {
                element.tabIndex = -1;
            });

            let currentSlide = this.dom.carousel[0].querySelector('.slick-track .slick-slide:nth-child(' + (this.dom.carousel.slick('slickCurrentSlide')+1) + ')');
            currentSlide.setAttribute('aria-hidden', 'false');
            let currentReadMore = currentSlide.querySelector('.read-more');
            if (currentReadMore) {
                currentReadMore.tabIndex = 0;
            }
        }
    }
}
