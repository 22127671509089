import Module from "./Module";
import ManageFocus from "./../services/manage-focus";

export default class ModuleModal extends Module {
	constructor(el) {
		super(el);

		this.navigated = false;
		this.calendar = false;
		
		$.extend(this.dom, {
			boxModal:      this.dom.el[0].querySelector('.js-modal'),
			openModal:     this.dom.el[0].querySelectorAll('.js-modal-open'),
			closeModal:    this.dom.el[0].querySelector('.js-modal-close'),
			contentModal:  this.dom.el[0].querySelector('.js-modal-content')
		});
	}
	
	init() {
		this.bindEvents();
	}
	 	
	bindEvents() {
		[].forEach.call(this.dom.openModal, (el) => {
			this.button = el;
			el.onclick = () => {
				let name = el.parentElement.getAttribute("data-name"),
					type  = el.parentElement.getAttribute("data-type"),
					video = el.parentElement.getAttribute("data-video"),
					carousel = el.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

				if (name){
					carousel.classList.add(name);
				}
		
				this.settingsOpenModal(el, el.parentElement);
				
				if (video == "true") {
					this.youtubeInit();
				}else if(video == "solo"){
					this.youtubeGalleryInit();
				}

				if(type == "gallery"){
					this.galleryInit(el.parentElement, name);
				}

				if(type == "calendar"){
					this.calendar = true;
				}

				this.dom.boxModal.setAttribute("tabIndex", "0");
			};
		});

		this.dom.closeModal.onclick = () => {
			this.settingsCloseModal();
		};

		this.dom.body.addEventListener('keydown', (e) => {
			if (this.dom.boxModal.classList.contains('is-open')) {
				if (e.keyCode == this.dom.keyboard.escape) {
					this.settingsCloseModal();
				}
			}
		});

		this.dom.boxModal.addEventListener('keydown', (e) => {
			if(this.navigated == false && this.dom.contentModal != null){
				if (e.keyCode == this.dom.keyboard.tab) {
					if (e.shiftKey) { /* shift + tab */ 
						if (document.activeElement == this.dom.contentModal) {
							this.dom.closeModal.focus();
							e.preventDefault();
						}
					} else {
						if (document.activeElement == this.dom.closeModal) {
							this.dom.contentModal.focus();
							e.preventDefault();
						}
					}
				}
			}
		});
	}

	youtubeInit() {
		if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
			let tag = document.createElement('script');
	
			tag.src = "https://www.youtube.com/iframe_api";
			this.firstScriptTag = document.getElementsByTagName('script')[0];
			this.firstScriptTag.parentNode.insertBefore(tag, this.firstScriptTag);
		}

		let player;
		let iframe = this.dom.boxModal.querySelector('iframe');

		if (iframe != null) {
		    			
			window.onYouTubeIframeAPIReady = () => {
				document.querySelectorAll('.modal__media-ytplayer').forEach((item) => {
					player = new YT.Player(`${item.id}`, {
						events: {
							'onReady' : onPlayerReady
						}
					});
				});
			}

			function onPlayerReady(event) {
				event.target.playVideo();
			}

		}
	}

	youtubeGalleryInit(){
		this.youtubeGallery = true;

		if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
			let tag = document.createElement('script');
	
			tag.src = "https://www.youtube.com/iframe_api";
			this.firstScriptTag = document.getElementsByTagName('script')[0];
			this.firstScriptTag.parentNode.insertBefore(tag, this.firstScriptTag);
		}
		
		var iframe = document.createElement("iframe");
		var embed = "https://www.youtube.com/embed/ID?autoplay=1";
		var modal = this.dom.body.querySelector("#modal .modal__media-content");

		modal.innerHTML = "";

		iframe.setAttribute("class", "modal__media-ytplayer");
		iframe.setAttribute("id", "video-" + this.dom.el[0].dataset.url);
		iframe.setAttribute("src", embed.replace("ID", this.dom.el[0].dataset.url));
		iframe.setAttribute("frameborder", "0");
		iframe.setAttribute("allowfullscreen", "1");
		iframe.setAttribute("allow", "autoplay");
		
		modal.appendChild(iframe);
	}

	galleryInit(current, name){
		this.currentIndex;
		this.openedContent = current;
		this.navigated = false;
		this.firstContent = this.dom.boxModal.cloneNode(true);

		let gallery = this.dom.body.querySelectorAll(`[data-name="${name}"]`),
			galleryToArray = Array.apply(null, gallery),
			carousel = this.dom.body.querySelector('.' + name + " .carousel__items"),
			prevButton = '<button type="button" class="modal__btn-prev"></button>',
			nextButton = '<button type="button" class="modal__btn-next"></button>';

		this.currentIndex = galleryToArray.indexOf(current);
		
		setTimeout(() => {
			this.dom.contentModal.focus();
		}, 100);

		if(gallery.length > 1){

			$('#modal .modal__media-container .modal__media-content:first').before(prevButton);
			$('#modal .modal__media-container .modal__close:last').before(nextButton);

			if (this.currentIndex == 0 && $(carousel).get(0).slick.options.infinite === false) {
				$(".modal__btn-prev").hide();
			} else if (this.currentIndex == galleryToArray.length - 1 && $(carousel).get(0).slick.options.infinite === false) {
				$(".modal__btn-next").hide();
			}
		
			this.dom.body.querySelector(".modal__btn-prev").addEventListener("click", (e) => {
				this.updateGallery("prev", galleryToArray, carousel, current);
				e.currentTarget.setAttribute("disabled", "disabled");
			});
	
			this.dom.body.querySelector(".modal__btn-next").addEventListener("click", (e) => {
				this.updateGallery("next", galleryToArray, carousel, current);
				e.currentTarget.setAttribute("disabled", "disabled");
			});	

			this.dom.boxModal.addEventListener('keyup', (e) => {
				if (e.keyCode == 37 && this.currentIndex != 0) {
					this.updateGallery("prev", galleryToArray, carousel, current);
					e.currentTarget.setAttribute("disabled", "disabled");
				}else if(e.keyCode == 39 && Number(this.currentIndex) + 1 < galleryToArray.length){
					this.updateGallery("next", galleryToArray, carousel, current);
					e.currentTarget.setAttribute("disabled", "disabled");
				}

				if (e.keyCode == this.dom.keyboard.tab) {
					if (e.shiftKey) { /* shift + tab */
						if (document.activeElement == this.dom.contentModal){
							this.dom.contentModal.querySelector(".modal__close").focus();
							e.preventDefault();
						}
					}
				} 
			});			
		}
	}

	updateGallery(dir, galleryToArray, carousel, currentContent){
		let modalContent = this.dom.body.querySelector("#modal .modal__media-content"),
			modalContainer = this.dom.body.querySelector("#modal .modal__media-container");

		this.navigated = true;

		if (currentContent.querySelector(".modal") == null){
			let cloneContent = this.dom.boxModal.cloneNode(true);

			currentContent.appendChild(cloneContent);
		}
		
		switch (dir) {
			case "prev":
				if (this.currentIndex == 0) {
					this.currentIndex = galleryToArray.length - 1;

				} else if (this.currentIndex == 1) {
					if ($(carousel).get(0).slick.options.infinite === false) {
						$(".modal__btn-prev").hide();
					}
					this.currentIndex = this.currentIndex - 1;

				} else {
					this.currentIndex = this.currentIndex - 1;
				}

				$(".modal__btn-next").show();
			break;

			case "next":
				if (this.currentIndex == galleryToArray.length - 1) {
					this.currentIndex = 0;

				} else if (this.currentIndex == galleryToArray.length - 2) {
					if ($(carousel).get(0).slick.options.infinite === false) {
						$(".modal__btn-next").hide();
					}
					this.currentIndex = this.currentIndex + 1;

				} else {
					this.currentIndex = this.currentIndex + 1;
				}

				$(".modal__btn-prev").show();

            break;

            default:
                break;
		}
		
		let newContent = galleryToArray[this.currentIndex].querySelector(".modal__media-content").innerHTML;
		modalContent.innerHTML = newContent;

		modalContainer.focus();

		let video = modalContent.querySelector("iframe");

		if (video != null){
			this.youtubeInit();	
		}

		this.dom.boxModal.addEventListener('keydown', (e) => {
			if (e.keyCode == this.dom.keyboard.tab) {
				if (e.shiftKey) { /* shift + tab */ 
					if (document.activeElement == modalContainer.querySelector(".modal__btn-prev")){
						modalContainer.querySelector(".modal__close").focus();
						e.preventDefault();
					}
					if (document.activeElement == modalContainer){
						modalContainer.querySelector(".modal__close").focus();
						e.preventDefault();
					}
				} else {
					if (document.activeElement == modalContainer.querySelector(".modal__close")){
						modalContainer.focus();
						e.preventDefault();
					}
				}
			}
		});	

		setTimeout(() => {
			$(".modal__btn-next, .modal__btn-prev").removeAttr("disabled");
		}, 200);
	}

	settingsOpenModal(element, parent) {
		this.dom.boxModal = parent.querySelector(".modal");
		this.dom.mediaModal = parent.querySelector(".modal .modal__media");

		let modal = document.createElement('div');
		modal.setAttribute('id', 'modal');

		modal.appendChild(this.dom.boxModal);

		modal.addEventListener("click", (e) => {
			var target = e.target;
			if (target === this.dom.mediaModal || target === this.dom.boxModal) {
				this.settingsCloseModal();
			}else{
				return;
			}
		});

		// Insert after #SITE
		this.insertAfter(modal, this.dom.site);

		this.posY = this.dom.w.scrollY;

		this.dom.html.classList.add('modal-open');
		this.dom.boxModal.classList.add('is-open');

		this.dom.boxModal.setAttribute('aria-modal', 'true');
		element.setAttribute('aria-expanded', 'true');
		this.dom.site.classList.add('blur');
		$(this.dom.boxModal).trigger('modalOpen');
		
		setTimeout(() => {
			if (this.dom.boxModal != null) {
				this.dom.boxModal.focus();
			}

		}, 100);
	}

	settingsCloseModal() {
		let video  = this.dom.boxModal.querySelector('iframe');
		let nextBtn = this.dom.boxModal.querySelector('.modal__btn-next');
		let prevBtn = this.dom.boxModal.querySelector('.modal__btn-prev');

		this.dom.site.classList.remove('blur');
		this.dom.html.classList.remove('modal-open');
		this.dom.boxModal.classList.remove('is-open');
		this.dom.boxModal.setAttribute('aria-modal', 'false');
		this.dom.w.scrollTo(0, this.posY);
		this.button.setAttribute('aria-expanded', 'false');

		if (this.navigated == true){
			let closeBtn = this.firstContent.querySelector('.modal__close');

			this.firstContent.classList.remove('is-open');
			this.firstContent.setAttribute('aria-modal', 'false');

			closeBtn.onclick = () => {
				this.settingsCloseModal();
			};
		}

		if(nextBtn != null){
			nextBtn.parentNode.removeChild(nextBtn);			
		}

		if(prevBtn != null){
			prevBtn.parentNode.removeChild(prevBtn);			
		}
							
		if (video != null) {
			YT.PlayerState == 0;
		}

		if(this.youtubeGallery == true){
			this.dom.body.querySelector("#modal .modal__media-content").innerHTML = "";
			this.youtubeGallery = false;
		}

		setTimeout(() => {
			if (this.navigated == true){
				let modalDiv = this.openedContent.querySelector(".is-open");

				modalDiv.parentNode.removeChild(modalDiv);
				this.openedContent.appendChild(this.firstContent);
			}else if(this.calendar == true){
				this.dom.el[0].querySelector('[data-module="Modal"]').appendChild(this.dom.boxModal);
				this.calendar = false;
			}else{
				this.dom.el[0].appendChild(this.dom.boxModal);
			}

			this.dom.body.removeChild(document.getElementById('modal'));

		}, 200);

		this.button.focus();
	}

	insertAfter(newNode, referenceNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
	}
}