import Module from "./Module";

export default class ModuleForm extends Module {
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            select: this.dom.el.find('select'),
            btnSubmit: this.dom.el[0].querySelector('.js-submit'),
            fieldMsg: this.dom.el[0].querySelector('.js-field-msg'),
            inputs: this.dom.el.find('input, textarea')
        });

    }

    init() {
        this.initSelect();
        this.handleFocus();
        this.bindEvents()
    }

    initSelect() {
        [].forEach.call(this.dom.select, (value, index) => {
            $(value).select2({
                width: '100%',
                dropdownParent: $(value).parent(),
                minimumResultsForSearch: -1,
                placeholder: value.dataset.placeholder
            });
        });

        this.dom.select.on('select2:closing', (e) => {
            if (e.target.value != '') {
                this.selectValue = e.target.value;
            }

            if (e.target.value == '' && this.selectValue != undefined) {
                e.target.value = this.selectValue;
            }
        });
    }


    bindEvents() {
        let inputRequired = this.dom.el[0].querySelectorAll('input, select, textarea');
        let newValidation = true;
        let errList = document.getElementById('form__error-list');

        // Set class error on submit
        this.dom.btnSubmit.onclick = () => {
            [].forEach.call(inputRequired, (value, index) => {
                value.setCustomValidity('');

                let inputErrorMessage = document.getElementById(`${value.id}_error`);
                let select2El = value.nextElementSibling;
                let select2Container = select2El.querySelector('.select2-selection');

                if (!value.validity.valid) {
                    value.classList.add('-error');
                    value.setCustomValidity(value.dataset.errorMessage);

                    if (newValidation) {
                        errList.innerHTML = '';
                        newValidation = false;
                    }

                    let inputErrLabel = value.nextElementSibling.innerText.replace(/\*/g, '');
                    let errMessage = document.createElement('li');
                    errMessage.innerHTML = `<a href="#${value.id}" class="form__error-anchor">${inputErrLabel}</a>`;

                    errList.appendChild(errMessage);
                    this.dom.fieldMsg.setAttribute('tabindex', '0');
                    setTimeout(() => {
                        this.dom.fieldMsg.focus()
                    }, 100);

                    //click anchor
                    let anchor = errMessage.querySelector('.form__error-anchor');

                    this.handleAnchorPosition(anchor);

                    value.setAttribute('aria-describedby', `${value.id}_error`);
                    inputErrorMessage.setAttribute('aria-hidden', false);

                    if (value.nextElementSibling.classList.contains('select2')) {
                        select2Container.setAttribute('aria-labelledby', `${value.id}_error`);
                    }
                } else {
                    value.classList.remove('-error');

                    if (inputErrorMessage != null) {
                        value.setAttribute('aria-describedby', '');
                        inputErrorMessage.setAttribute('aria-hidden', true);

                        if (value.nextElementSibling.classList.contains('select2')) {
                            select2Container.setAttribute('aria-labelledby', `select2-${value.id}-container`);
                        }
                    }
                }
            });

            if (this.dom.el[0].checkValidity()) {
                this.dom.fieldMsg.classList.remove('has-error');
            } else {
                this.dom.fieldMsg.classList.add('has-error');
            }

            newValidation = true;
        }

        this.dom.el.on('submit', (e) => {
            e.preventDefault();

            if (this.dom.el[0].checkValidity()) {
                this.validateFormData(e);
            }
        });

        this.dom.el[0].addEventListener('keyup', (e) => {
            if (e.keyCode == this.dom.keyboard.tab) {
                if (e.target.classList.contains('select2-selection')) {
                    e.target.classList.add('select2-outline');
                }

                if (e.target.classList.contains('is-active')) {
                    e.target.classList.add('is-focus');
                }
            }
        });

        this.dom.el[0].addEventListener('keydown', (e) => {
            if (e.keyCode == this.dom.keyboard.tab) {
                if (e.target.classList.contains('select2-selection')) {
                    e.target.classList.remove('select2-outline');
                }

                if (e.target.classList.contains('is-active')) {
                    e.target.classList.remove('is-focus');
                }
            }
        });
    }

    validateFormData(e) {
        $.ajax({
            type: 'POST',
            url: this.dom.el[0].dataset.api,
            data: this.dom.el.serialize(),
            datatype: 'json'
        })
            .done((data, textStatus, jqXHR) => {
                window.location = this.dom.el[0].dataset.successpage;
                this.dom.el[0].reset();
            })
            .fail((jqXHR, textStatus, errorThrown) => {
                if (jqXHR.status == 400 && jqXHR.responseText != null) {
                    this.dom.el[0].checkValidity();

                    this.handleServerErrors(jqXHR, 'FirstName');
                    this.handleServerErrors(jqXHR, 'LastName');
                    this.handleServerErrors(jqXHR, 'Email');
                    this.handleServerErrors(jqXHR, 'PhoneNumber');
                    this.handleServerErrors(jqXHR, 'PostalCode');
                    this.handleServerErrors(jqXHR, 'Message');

                    if (jqXHR.responseText.data_gtm) {
                        {
                            for (var i = 0; i < jqXHR.responseText.data_gtm.length; i++) {                            
                                window.dataLayer.push(jqXHR.responseText.data_gtm[i]);
                            }
                        }
                    }    

                } else {
                    window.location = this.dom.el[0].dataset.errorpage;
                    this.dom.el[0].reset();
                }
            });
    }

    setInputError(value) {
        let errList = document.getElementById('form__error-list');
        let inputErrorMessage = document.getElementById(`${value.id}_error`);
        let select2El = value.nextElementSibling;
        let select2Container = select2El.querySelector('.select2-selection');

        value.classList.add('-error');
        value.setCustomValidity(value.dataset.errorMessage);

        let inputErrLabel = value.nextElementSibling.innerText.replace(/\*/g, '');
        let errMessage = document.createElement('li');
        errMessage.innerHTML = `<a href="#${value.id}" class="form__error-anchor">${inputErrLabel}</a>`;

        errList.appendChild(errMessage);
        this.dom.fieldMsg.setAttribute('tabindex', '0');
        setTimeout(() => {
            this.dom.fieldMsg.focus()
        }, 100);

        //click anchor
        let anchor = errMessage.querySelector('.form__error-anchor');

        this.handleAnchorPosition(anchor);

        value.setAttribute('aria-describedby', `${value.id}_error`);
        inputErrorMessage.setAttribute('aria-hidden', false);

        if (value.nextElementSibling.classList.contains('select2')) {
            select2Container.setAttribute('aria-labelledby', `${value.id}_error`);
        }
    }

    handleServerErrors(xhr, field) {
        if (xhr.responseText.includes(field)) {
            for (let i = 0; i < this.dom.inputs.length; i++) {
                if (this.dom.inputs[i].getAttribute("name") == field) {
                    this.setInputError(this.dom.inputs[i]);
                    this.dom.fieldMsg.classList.add('has-error');
                    break;
                }
            }
        }
    }

    handleFocus() {
        let inputTypes = 'input[type=text], input[type=email], input[type=password], input[type=date], input[type=tel], input[type=number]';
        let inputs = this.dom.el[0].querySelectorAll(inputTypes);
        let textareas = this.dom.el[0].querySelectorAll('textarea');
        this.handleFocusOnElements(inputs);
        this.handleFocusOnElements(textareas);
    }

    handleFocusOnElements(elements) {
        [].forEach.call(elements, (element) => {
            element.addEventListener('focus', (el) => {
                element.classList.add('is-active');
            });
            element.addEventListener('blur', (el) => {
                if (!el.target.value.length || el.target.value.trim().length === 0) {
                    element.classList.remove('is-active');
                }
            });
        });
    }

    handleAnchorPosition(anchor) {
        anchor.addEventListener('click', (e) => {
            e.preventDefault();
            let target = $(e.target.getAttribute('href'));
            if (target.length) {
                $('html, body').stop().animate({
                    scrollTop: target.offset().top - 100
                });
            }
            target.focus();
        });
    }
}
