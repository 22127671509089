import ManageFocus from "./services/manage-focus";
import ManageUrlAnchor from "./services/manage-url-anchor";

var Modules = {
    Gtm: require('./modules/ModuleGtm'),
    GA4: require('./modules/ModuleGA4'),
    Modal: require('./modules/ModuleModal'),
    MainMenu: require('./modules/ModuleMainMenu'),
    TicketsMenu: require('./modules/ModuleTicketsMenu'),
    Form: require('./modules/ModuleForm'),
    FormValidator: require('./modules/ModuleFormValidator'),
    Dropdown: require('./modules/ModuleDropdown'),
    Calendar: require('./modules/ModuleCalendar'),
    Generic: require('./modules/ModuleGeneric'),
    ShowCarousel: require('./modules/ModuleShowCarousel'),
    CarouselShowDetails: require('./modules/ModuleCarouselShowDetails'),
    DealsCarousel: require('./modules/ModuleDealsCarousel'),
    MediaCarousel: require('./modules/ModuleMediaCarousel'),
    ButtonCarousel: require('./modules/ModuleButtonCarousel'),
    Accordion: require('./modules/ModuleAccordion'),
    CarouselHighlights: require('./modules/ModuleCarouselHighlights'),
    AutoScroll: require('./modules/ModuleAutoScroll'),
    Newsletter: require('./modules/ModuleNewsletter'),
    Video: require('./modules/ModuleVideo'),
    ReadMore: require('./modules/ModuleReadMore'),
    CastingCarousel: require('./modules/ModuleCarouselCasting'),
    OffersTabs: require('./modules/ModuleOffersTabs'),
    Tooltips: require('./modules/ModuleTooltips'),
    Locations: require('./modules/ModuleLocations'),
    Quote: require('./modules/ModuleQuote'),
    Footer: require('./modules/ModuleFooter'),
    VideoGallery: require('./modules/ModuleVideoGallery'),
    ButtonList: require('./modules/ModuleButtonList')
};

var Views = {
    Main: require('./views/View'),
    Home: require("./views/ViewHome"),
    GroupContactUs: require("./views/ViewGroupContactUs")
};

$(function () {
    initClassesBMG($(document.querySelector("#site")));
    ManageFocus();
    ManageUrlAnchor();
});

window.initClassesBMG = function (context) {
    _.each(context.find("[data-view]"), function (el) {
        let list = $(el)
            .data("view")
            .split(/\s+/);
        _.each(list, function (name) {
            if (Views[name] !== undefined) {
                new Views[name].default($(el)).init();
            } else {
                new Views['Main'].default($(el)).init();
            }
        });
    });

    _.each(context.find("[data-module]"), function (el) {
        let list = $(el)
            .data("module")
            .split(/\s+/);
        _.each(list, function (name) {
            if (Modules[name] !== undefined) {
                let m = new Modules[name].default($(el));
                m.init();
            }
        });
    });
};
