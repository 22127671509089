import Module from "./Module";

export default class ModuleReadMore extends Module {
    constructor(el) {
        super(el);

        this.textContainer = this.dom.el;
        this.text = this.dom.el[0].innerText;
    }

    init() {
        let varText = this.text;
        let limit = this.dom.el[0].dataset.limit;

        if (varText.length > limit) {
            this.trimText(varText, limit);
        }

    }

    trimText(varText, limit) {
        let textContainer = this.textContainer,
            textContent = varText,
            trigger = document.createElement("button"),
            triggerText = document.createTextNode(this.dom.el[0].dataset.label);

        textContainer[0].innerText = textContent.substring(0, limit - 5);
        textContainer[0].innerText = textContainer[0].innerText.substr(0, Math.min(295, textContainer[0].innerText.lastIndexOf(" "))) + "...";

        trigger.appendChild(triggerText);
        textContainer[0].appendChild(trigger);

        trigger.addEventListener("click", () => {
            textContainer[0].innerText = varText;
        });
    }
}