import Module from "./Module";

export default class ModuleGeneric extends Module {
	constructor(el) {
		super(el);
		
		$.extend(this.dom, {
			readMore:        this.dom.el[0].querySelector('.read-more'),
			readLess:        this.dom.el[0].querySelector('.read-less'),
            readMoreContent: this.dom.el[0].querySelector('.generic-content__more'),
            readMoreSummary: this.dom.el[0].querySelector('.generic-content__summary')
		});
	}
	
	init() {
		this.bindEvents();
	}

	bindEvents() {
        if (this.dom.readMore) {
            
            let allElem = this.dom.readMoreSummary.children;
            
            if (allElem.length) {
                allElem[allElem.length - 1].appendChild(this.dom.readMore);
            }

            this.dom.readMore.addEventListener('click', (event) => {
				event.preventDefault();

				this.readMore();
            });

            this.dom.readMore.addEventListener('keydown', (event) => {
                if (event.keyCode == this.dom.keyboard.spacebar || event.keyCode == this.dom.keyboard.enter) {
                    event.preventDefault();

                    this.readMore();
                }
			});
			
			this.dom.readLess.addEventListener('click', (event) => {
				event.preventDefault();

                this.readLess();
            });

            this.dom.readLess.addEventListener('keydown', (event) => {
                if (event.keyCode == this.dom.keyboard.spacebar || event.keyCode == this.dom.keyboard.enter) {
                    event.preventDefault();

                    this.readLess();
                }
            });
        }
    }
    
    readMore() {
        this.dom.readMore.style.display = 'none';
        this.dom.readMore.setAttribute('aria-expanded','true');
        this.dom.readLess.style.display = 'inline-block';
        this.dom.readMoreContent.setAttribute('aria-hidden','false');

		$(this.dom.readMoreContent).slideToggle();
	}
	
	readLess() {
        this.dom.readLess.style.display = 'none';
        this.dom.readMore.setAttribute('aria-expanded','false');
        this.dom.readMore.style.display = 'inline-block';
        this.dom.readMoreContent.setAttribute('aria-hidden','true');

		$(this.dom.readMoreContent).slideToggle();
    }
}