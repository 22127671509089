import Module from './Module';

export default class ModuleGtm extends Module {
	constructor(el) {
		super(el);
	}

	//-- Methods
	//--------------------------------------------------------------

	init() {
		this.initGtmButtons();
	}

	initGtmButtons() {
		// =================================================================
		// If modified, please check for the FrontEnd version
		// =================================================================

		var domElements = this.dom.el;
		var propertyName = 'ModuleGtm_initGtmButtons_pushEvent_added';
		var controls = domElements.find('[data-gtm]');

		for (var i = 0; i < controls.length; i++) {
			addEventToElement(controls[i], 'click', pushEvent);
		}

		function addEventToElement(element, eventName, eventFunction) {
			if (!element[propertyName]) {
				element.addEventListener(eventName, eventFunction);
				element[propertyName] = true;
			}
		}

		function pushEvent() {
			var eventAttributes = buildTagProperties(this);
			if (eventAttributes) {
				for (var i = 0; i < eventAttributes.length; i++) {
					window.dataLayer.push(eventAttributes[i]);
				}
			}
		}

		function buildTagProperties(obj) {
			return cleanJson(obj.dataset['gtm']);
		}

		function cleanJson(strJson) {
			var s = strJson
				.replace(/\\n/g, '\\n')
				.replace(/\\'/g, "\\'")
				.replace(/\\"/g, '\\"')
				.replace(/\\&/g, '\\&')
				.replace(/\\r/g, '\\r')
				.replace(/\\t/g, '\\t')
				.replace(/\\b/g, '\\b')
				.replace(/\\f/g, '\\f');

			// remove non-printable and other non-valid JSON chars
			s = s.replace(/[\u0000-\u0019]+/g, '');
			return JSON.parse(s);
		}
	}
}
