import Module from "./Module";
import SimpleBar from 'simplebar';

export default class ModuleMainMenu extends Module {
	constructor(el) {
		super(el);
		
		$.extend(this.dom, {
			menu:          this.dom.el[0].querySelector('.menu'),
			menuTitle:     this.dom.el[0].querySelector('.menu__title'),
			menuScrollbar: this.dom.el[0].querySelector('.menu__scrollbar'),
			subMenu:       this.dom.el[0].querySelector('.menu__sub'),
			cta:           this.dom.el[0].querySelectorAll('.js-cta')
		});

		this.menuElementFirstHeight = [];
	}
	
	init() {
		new SimpleBar(this.dom.menuScrollbar);
		
		this.bindEvents();
		this.subMenu();
		this.changeFocus();
	}

	bindEvents() {
	}

	subMenu() {
		let menu = this.dom.menu;
		let ctas = this.dom.cta;
		let thisModule = this;
		let resizeTimer;

		function closeSubMenu(el) {
			[].forEach.call(menu.children, (element, ind) => {
				if(el.parentElement != menu.children[ind]) {
					let allChildren = menu.children[ind];
					let subMenu     = allChildren.querySelector('.menu__sub');
					let button      = menu.children[ind].querySelector('button')

					allChildren.classList.remove('open');
					
					if (subMenu != null) {
						allChildren.style.height = `${thisModule.menuElementFirstHeight[ind]}px`;
						subMenu.style.height = 'auto';
					}

					if (button != null) {
						button.setAttribute('aria-expanded', false);
					}
				}
			});
		}

		function openSubMenu(el, i) {
			let setAria  = el.getAttribute('aria-expanded');
			let menuItem = el.parentElement;
			let submenu  = menuItem.querySelector('.menu__sub');

			//Open submenu and set aria-expanded
			menuItem.classList.toggle('open');
			
			if (setAria == 'true') {
				el.setAttribute('aria-expanded', 'false');
				menuItem.style.height = `${thisModule.menuElementFirstHeight[i]}px`;
				submenu.style.height = 'auto';
			} else {
				el.setAttribute('aria-expanded', 'true');
				submenu.style.height = 'auto';
				menuItem.style.height = `${menuItem.scrollHeight}px`;
			}
		}

		[].forEach.call(ctas, (element, index) => {
			if (element.classList.contains('menu__title')) {
				['click'].forEach(event => {
					ctas[index].addEventListener(event, (e) => {
						let cta = e.target;
						closeSubMenu(cta);
						openSubMenu(cta, index);
					});
				});
			}
		});

		document.addEventListener('keyup', (e) => {
			let link = e.target;
			if (e.keyCode == 9) {
				if (link.classList.contains('js-cta')) {
					let cta     = e.target;
					closeSubMenu(cta);
					openSubMenu(cta);
				}
			}
		});

		
		this.dom.w.addEventListener('resize', () => {
			clearTimeout(resizeTimer);

			resizeTimer = setTimeout(function() {
				[].forEach.call(menu.children, (element, ind) => {
					let allChildren = menu.children[ind];
					let subMenu     = allChildren.querySelector('.menu__sub');
					let button      = menu.children[ind].querySelector('button')

					allChildren.classList.remove('open');

					if (button != null) {
						button.setAttribute('aria-expanded', false);
					}
					
					if (subMenu != null) {
						subMenu.style.height = 'auto';
						subMenu.style.height = '0';
					}

					allChildren.style.height = `auto`;
					allChildren.style.height = `${allChildren.scrollHeight}px`;
				});
			}, 250);
		});
	}

	changeFocus() {
		let modal      = this.dom.el[0].offsetParent;
		let closeModal = modal.querySelector('.js-modal-close');
		let menu       = this.dom.menu;
		let menuCta    = this.dom.menu.querySelectorAll('a, button');
		let thisModule = this;

		$(document).on('modalOpen', () => {
			if(modal.classList.contains('is-open')) {
				setTimeout(() => {
					let menuTitle = menu.querySelectorAll('.js-cta');
					menuTitle[0].focus();

					[].forEach.call(menuTitle, (element, i) => {
						thisModule.menuElementFirstHeight.push(menuTitle[i].parentElement.offsetHeight);
						menuTitle[i].parentElement.style.height = `${menuTitle[i].parentElement.offsetHeight}px`;
					});
				}, 100);
			}
		});

		document.addEventListener('keydown', (e) => {
			if (modal.classList.contains('is-open')) {
				let firstMenuElement = menu.querySelectorAll('.js-cta')[0];

				if (e.keyCode == this.dom.keyboard.tab ) {
					if (e.shiftKey) { /* shift + tab */ 
						if (document.activeElement == firstMenuElement) {
							closeModal.focus();
							e.preventDefault();
						}
					} else {
						if (document.activeElement == closeModal) {
							firstMenuElement.focus();
							e.preventDefault();
						}
					}
				}
			}
		});

		this.dom.menu.addEventListener('keydown', (e) => {
			if (e.keyCode == this.dom.keyboard.arrowDown) {
				[].forEach.call(menuCta, (element, i) => {
					if (e.target == menu) {
						menuCta[0].focus();
						e.preventDefault();
					} else {
						let index = Array.prototype.indexOf.call(menuCta, e.target);
						index = index + 1;


						if (index == menuCta.length) {
							e.preventDefault();
						} else {
							let prevSubMenu = this.parentUpTo(menuCta[index], 'menu__sub');

							if (prevSubMenu) {
								prevSubMenu.parentElement.classList.add('open');
								prevSubMenu.previousElementSibling.setAttribute('aria-expanded', 'true');
							}

							if (menuCta[i].tagName == 'BUTTON') {
								menuCta[i].setAttribute('aria-expanded', 'false');
								menuCta[i].parentElement.classList.remove('open');
							}

							menuCta[index].focus();
							e.preventDefault();
						}
					}
				});
			}

			if (e.keyCode == this.dom.keyboard.arrowUp) {
				[].forEach.call(menuCta, (element, i) => {
					if (e.target == menu) {
						e.preventDefault();
					} else {
						let index = Array.prototype.indexOf.call(menuCta, e.target) - 1;
						
						if (index == -1) {
							e.preventDefault();
						} else  {
							let prevSubMenu = this.parentUpTo(menuCta[index], 'menu__sub');

							if (prevSubMenu) {
								prevSubMenu.parentElement.classList.add('open');
								prevSubMenu.previousElementSibling.setAttribute('aria-expanded', 'true');
							}

							//Remove all class open
							if (menuCta[i].tagName == 'BUTTON') {
								menuCta[i].setAttribute('aria-expanded', 'false');
								menuCta[i].parentElement.classList.remove('open');
							}

							//Leave submenu open when back on button
							if (menuCta[index].tagName == 'BUTTON') {
								menuCta[index].parentElement.classList.add('open');
								menuCta[index].setAttribute('aria-expanded', 'true');
							}

							menuCta[index].focus();
							e.preventDefault();
						}
					}
				});
			}
		});
	}

	parentUpTo(el, cls) {
		while ((el = el.parentElement) && !el.classList.contains(cls));
		return el;
	}
}