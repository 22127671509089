import Module from './Module';

export default class ModuleFooter extends Module {
    constructor(el) {
        super(el);
    }

    //-- Methods
    //--------------------------------------------------------------

    init() {
        this.checkForSticky();
    }

    checkForSticky() {
        let check = document.querySelector('[data-component="StickyDrawer"]');
        if (check !==null){
            this.addClass("withSticky");
        }
    }

    addClass(name){
        this.dom.el[0].classList.add(name);
    }
}