// -------------------------------------------------------------
// ------------         NOT USED  ANYMORE        ---------------
//          Keeping it just in case we need a tab module 
// -------------------------------------------------------------

import Module from './Module'; 
import Popper from 'popper.js';
import Tooltip from 'tooltip.js';

export default class ModuleOffersTabs extends Module {

    constructor(el) {
        super(el);

    }
    
    init() {
        console.log(this.dom.el)
        this.arrayBtns = this.dom.el[0].querySelectorAll('.button-list__cta');
        this.arrayTabs = this.dom.el[0].querySelectorAll('.offers-container');
        console.log(this.arrayBtns);
        console.log(this.arrayTabs);
        this.bindEvents();
        // this.handleTooltip();
    }

    bindEvents() {
        Array.from(this.arrayBtns).map((btn, index) => {
            btn.addEventListener('click', () => {
                this.closeAllTabs();
                console.log(index);
                this.openTab(index);
                // btn.classList.add('-selected');
            })
        });
    }

    closeAllTabs() {
        Array.from(this.arrayTabs).map(tab => {
            tab.style.display = "none"; 
        })
        Array.from(this.arrayBtns).map(btn => {
            btn.classList.remove('-selected'); 
        })
        
    }

    openTab(tabNumber) {
        this.arrayTabs[tabNumber].style.display = "block";
        this.arrayBtns[tabNumber].classList.add('-selected');
    }

    // handleTooltip() {
    //     var reference = document.querySelector('.offer__tooltip-btn');
    //     var popper = document.querySelector('.offer__tooltip');
    //     var instance = new Tooltip(popper,{
    //         title: popper.dataset.tooltip,
    //         trigger: "hover focus click",
    //         placement: 'right',
    //       });
    // }
}