import Module from './Module';

export default class ModuleDealsCarousel extends Module {
    
    constructor(el) {
        super(el);
        $.extend(this.dom, {
            carousel:      this.dom.el.find('.carousel__items'),
            buttonWrapper: this.dom.el[0].querySelector('.carousel__btn-wrapper')
		});
		
		this.settings = {
            accessibility: false,
            infinite: false,
			arrows: false,
            dots: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            cssEase: 'linear',
            mobileFirst: true,
            rows: 0,
            responsive: [
                {
					breakpoint: 600,
					settings: {
                        slidesToShow: 2
                	}
                },
                {
					breakpoint: this.dom.breakpoint.sm - 1,
					settings: {
                        slidesToScroll: 2,
                        slidesToShow: 2
                	}
                },
                {
					breakpoint: 850,
					settings: {
                        slidesToScroll: 3,
                        slidesToShow: 3
                	}
                },
                {
					breakpoint: this.dom.breakpoint.md - 1,
					settings: {
                        slidesToScroll: 3,
                        slidesToShow: 3,
                        arrows: true,
						appendArrows: this.dom.buttonWrapper
                	}
                }
            ]
		}
    }

    init() {
        this.dom.carousel.slick(this.settings);
    
        if(this.dom.el[0].querySelector('.slick-next')) {
            if(this.dom.w.innerWidth >= this.dom.breakpoint.sm) {
                let prevButtonText = this.dom.el[0].querySelector('.slick-prev').innerHTML = this.dom.buttonWrapper.dataset.buttonPrev;
                let nextButtonText = this.dom.el[0].querySelector('.slick-next').innerHTML = this.dom.buttonWrapper.dataset.buttonNext;
            }
        }

        let dotsContainer = this.dom.el[0].querySelector('.slick-dots');
		
		if(dotsContainer){
			dotsContainer.setAttribute('role','presentation');

			let dots = this.dom.el[0].querySelectorAll('.slick-dots button');
	
			[].forEach.call(dots, function(element) {
				element.setAttribute('tabindex','-1');
			});
        } 
    }

}
