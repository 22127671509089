import Module from "./Module";

export default class ModuleVideoGallery extends Module {
	constructor(el) {
		super(el);
	}

	init() {
        this.index;
        this.maxItems = this.dom.el[0].querySelector('.video-gallery__filters').dataset.maxitems;
        this.loadMoreCount = this.dom.el[0].querySelector('.video-gallery__filters').dataset.loadmorecount;
        this.loadMoreBtn = this.dom.el[0].querySelector(".video-gallery__btn-load-more");
        this.closeLabel = this.dom.el[0].dataset.closelabel;
        this.modalLabel = this.dom.el[0].dataset.modallabel;

        var json = this.dom.el[0].dataset.videogalleryitems;

        this.obj = JSON.parse(json);
        this.list = this.obj;

        if(this.maxItems > this.list.length){
            this.populateGallery(0, this.list.length, this.list);
        }else{
            this.populateGallery(0, this.maxItems, this.list);
        }

        if(this.list.length > this.maxItems){
            this.loadMoreBtn.style.display = "inline-block";
            
            this.loadMoreBtn.addEventListener("click", () => {
                document.querySelector(".video-gallery__list-spinner").style.display = "block";

                setTimeout(() => {
                    this.loadMore(this.list);
                }, 100);

                this.loadMore.disabled = true;
            });
        }

        this.galleryItems = this.dom.el[0].querySelectorAll('.video-gallery__list [data-category]');
        this.galleryFilters = this.dom.el[0].querySelectorAll('.video-gallery__filters [data-category]');

        Array.from(this.galleryFilters).map((btn, index) => {
            btn.addEventListener('click', (e) => {
                this.updateFilter(btn.dataset.category);

                btn.classList.add('-selected');
                btn.disabled = true;
                
                $(btn).siblings().removeClass('-selected');
                $(btn).siblings().removeAttr('disabled');
            });
        });
    }

    populateGallery(index, maxItems, gallery){
        for (var i = index, len = maxItems; i < len; ++i) {
            var object = gallery[i];
            var video = '<li class="video-gallery__list-item" data-category="' + object.category + '"> <div class="video-gallery__list-thumbnail"><img class="video-gallery__list-img" src="'+ object.thumbnail +'"> <div data-module="Modal" data-url="' + object.url +'" data-type="video" data-video="solo"> <button class="video-gallery__list-modal js-modal-open" aria-label="' + this.modalLabel + '"></button> <div class="modal js-modal" role="dialog" aria-modal="false"> <div class="modal__media"> <div class="modal__media-container js-modal-content" tabindex="0"> <div class="modal__media-content js-modal-content video-'+ object.url +' tabindex="0"></div> <button class="modal__close -next-to-box js-modal-close" aria-label="'+ this.closeLabel +'"></button> </div></div> </div></div></div> <h2 class="video-gallery__list-title">'+ object.title +'</h2></li>';

            document.querySelector(".video-gallery__list-items").innerHTML += video;
            this.index = i;
        }

        document.querySelector(".video-gallery__list-spinner").style.display = "none";

        window.initClassesBMG($(".video-gallery__list-items"));
    }
    
    loadMore(gallery){
        if(gallery.length < Number(this.index) + Number(this.loadMoreCount)){
            this.populateGallery(Number(this.index) + 1, gallery.length, gallery);
        }else if(gallery.length == Number(this.index) + Number(this.loadMoreCount) + 1){
            this.populateGallery(Number(this.index) + 1, Number(this.index) + Number(this.loadMoreCount) + 1, gallery);
        }else{
            this.populateGallery(Number(this.index) + 1, Number(this.index) + Number(this.loadMoreCount), gallery);
        }
        
        if(Number(this.index) + 1 >= gallery.length){
            this.loadMoreBtn.style.display = "none";
        }

        this.loadMore.disabled = false; 
    }

    updateFilter(filter) {
        this.index = 0;
        let galleryItemsToArray = Array.apply(null, this.obj);

        this.loadMoreBtn.style.display = "none";
        this.loadMore.disabled = false;

        if(filter === "all"){
            var result = galleryItemsToArray;
        }else{
            var result = galleryItemsToArray.filter(item => item.category == filter);
        }

        this.list = result;
        this.dom.body.querySelector(".video-gallery__list-items").innerHTML = "";

        document.querySelector(".video-gallery__list-spinner").style.display = "block";

        setTimeout(() => {
            if(result.length >= 8){
                this.populateGallery(0, this.maxItems, result);
            }else{
                this.populateGallery(0, result.length, result);
            }

            if(result.length > this.maxItems){
                this.loadMoreBtn.style.display = "inline-block";
            }

        }, 100);
    }

}