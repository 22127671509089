export default function ManageUrlAnchor() {
	function init() {
		updateScrollPosition();

		window.addEventListener('hashchange', function(e) {
			updateScrollPosition();
		});

		window.addEventListener('popstate', function(e) {
			updateScrollPosition();
		});
	}

	function updateScrollPosition() {
		let elTarget = (location.hash)? document.querySelector(location.hash) : null;
		let pagePadding = 70;

		if (elTarget && document.getElementById("faq") !== null) {
			setTimeout(function() {
				let elementOffset = elTarget.getBoundingClientRect().y + window.pageYOffset - pagePadding;
	
				if (history && history.scrollRestoration) {
					history.scrollRestoration = 'manual';
				}

				window.scroll(0, elementOffset);

				setTimeout(function() {
					if (history && history.scrollRestoration) {
						history.scrollRestoration = 'auto';
					}
				}, 5000);
			}, 1750);
		}else if(elTarget){
			let elementOffset = elTarget.getBoundingClientRect().y + window.pageYOffset - pagePadding;

			if (history && history.scrollRestoration) {
				history.scrollRestoration = 'manual';
			}

			window.scroll(0, elementOffset);

			setTimeout(function() {
				if (history && history.scrollRestoration) {
					history.scrollRestoration = 'auto';
				}
			}, 5000);
		}
	}

	init();
}
