/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import Mustache from 'mustache';
import Moment from 'moment';
import Module from './Module';
import ModuleModal from './ModuleModal';
import ModuleGtm from './ModuleGtm';
import ModuleGA4 from './ModuleGA4';

const GTM_DATE = {
	gtm: '<date>',
	ga4: '<show_date>',
};

const GTM_TIME = {
	gtm: '<time>',
	ga4: '<show_time>',
};

const GTM_PRICE = {
	gtm: '<startingprice>',
	ga4: '<starting_price>',
};

const GTM_DAYS = {
	gtm: '<daystoevent>',
	ga4: '<nb_days_to_event_date>',
};

const GTM_HOST = {
	gtm: '<urihost>',
	ga4: '<uri_host>',
};

const GTM_LINK_TEXT = {
	gtm: null,
	ga4: '<link_text>',
};

const GTM_LINK_URL = {
	gtm: null,
	ga4: '<link_url>',
};

const GTM_INTERACTION = {
	gtm: null,
	ga4: '<interaction_type>',
};

const GTM_BOOKING_ENGINE = {
	gtm: null,
	ga4: '<booking_engine>',
};

export default class ModuleCalendar extends Module {
	constructor(el) {
		super(el);

		$.extend(this.dom, {
			calendar: this.dom.el[0].querySelector('.calendar'),
			eventTemplate: document.getElementById('eventTemplate').innerHTML,
			priceTemplate: document.getElementById('priceTemplate').innerHTML,
			dictionary: this.dom.el[0].getAttribute('data-dictionary'),
			timeFormatInfo: this.dom.el[0].getAttribute('data-timeformat'),
			sharpTimeFormatInfo: this.dom.el[0].getAttribute('data-sharptimeformat'),
			buyButtonLabel: this.dom.el[0]
				.querySelector('.calendar')
				.getAttribute('data-link-url'),
			dateClickTemplate: this.dom.el[0]
				.querySelector('.calendar')
				.getAttribute('data-gtm-date-click'),
			dateClickTemplateGa4: this.dom.el[0]
				.querySelector('.calendar')
				.getAttribute('data-ga4-calendar-date'),
			timeClickTemplate: this.dom.el[0]
				.querySelector('.calendar')
				.getAttribute('data-gtm-time-click'),
			timeClickTemplateGa4: this.dom.el[0]
				.querySelector('.calendar')
				.getAttribute('data-ga4-calendar-time'),
			buyClickTemplate: this.dom.el[0]
				.querySelector('.calendar')
				.getAttribute('data-gtm-buy-click'),
			buyClickTemplateGa4: this.dom.el[0]
				.querySelector('.calendar')
				.getAttribute('data-ga4-calendar-buy'),
		});

		this.dayClicked = '';
		this.obj = {};
	}

	init() {
		this.initCalendar();
		this.bindEvents();
		this.eventPanelTimeChoosed();
	}

	bindEvents() {
		this.dom.calendar.addEventListener('keydown', (e) => {
			const el = e.target;

			if (
				e.keyCode == this.dom.keyboard.spacebar ||
				e.keyCode == this.dom.keyboard.enter
			) {
				if (el.classList.contains('has-event')) {
					let date = this.parentUpTo(el, 'fc-day-top');
					date = Moment(date.dataset.date);
					this.calendarDateSelected(date, e, this.dom.calendar);
				}

				if (el.classList.contains('js-panel-close')) {
					this.eventPanelClose();
					document.activeElement.classList.add('has-focus');
				}
			}

			if (e.keyCode == this.dom.keyboard.tab) {
				if (el.classList.contains('has-focus')) {
					el.classList.remove('has-focus');
				}
			}
		});

		this.dom.calendar.addEventListener('keyup', (e) => {
			const el = e.target;

			if (e.keyCode == this.dom.keyboard.tab) {
				if (el.classList.contains('fc-day-number')) {
					el.classList.add('has-focus');
				}
			}
		});

		this.dom.calendar.addEventListener('click', (e) => {
			const el = e.target;
			const allFocusedElement = this.dom.calendar.querySelectorAll(
				'.has-focus'
			);

			if (el.classList.contains('js-panel-close')) {
				this.eventPanelClose();
			}

			for (let x = 0; x < allFocusedElement.length; x++) {
				if (allFocusedElement[x].classList.contains('has-focus')) {
					allFocusedElement[x].classList.remove('has-focus');
				}
			}
		});
	}

	initCalendar() {
		this.dom.calendar.absoDataCache = {};

		$(this.dom.calendar).fullCalendar({
			locale: this.dom.html.getAttribute('lang'),
			eventColor: '#000',
			displayEventTime: false,
			displayEventEnd: false,
			navLinks: false,
			showNonCurrentDates: false,
			fixedWeekCount: false,
			lazyFetching: true,
			contentHeight: 'auto',
			titleFormat: 'MMMM',
			header: {
				left: 'prev',
				center: 'title',
				right: 'next',
			},

			events: (start, end, timezone, callback) => {
				const key = `|${start}|${end}|`;

				if (this.dom.calendar.absoDataCache[key] != undefined) {
					callback(this.dom.calendar.absoDataCache[key]);
				} else {
					$.ajax({
						type: 'GET',
						url: this.dom.el[0].dataset.api,
						data: {
							itemId: this.dom.el[0].dataset.itemid,
							lang: this.dom.el[0].dataset.lang,
							showId: this.dom.el[0].dataset.showid,
							startDate: start.toISOString(),
							endDate: end.toISOString(),
						},
						dataType: 'json',
						success: (data) => {
							this.dom.calendar.absoDataCache[key] = data;
							callback(data);
						},
						error(xhr, status, error) {
							console.log(error);
						},
					});
				}
			},

			eventAfterAllRender: (view) => {
				const currentMonth = Moment().format('YYYY-MM');
				const viewMonth = view.intervalStart.format('YYYY-MM');

				if (viewMonth == currentMonth) {
					view.calendar.el[0].querySelector('.fc-prev-button').disabled = true;
				}
			},

			viewRender: (view, el) => {
				this.changeAspectRatio();

				// ADA
				const toolbar = view.calendar.el[0].querySelector('.fc-header-toolbar');
				const toolbarTitle = toolbar.querySelector('.fc-center');

				const moment = $(this.dom.calendar).fullCalendar('getDate');
				const weekHeader = view.el[0].querySelector('.fc-head-container ');
				const weekDays = weekHeader.querySelectorAll('.fc-day-header');
				const dictionary = JSON.parse(this.dom.dictionary);

				// Button
				toolbar
					.querySelector('.fc-prev-button')
					.setAttribute('aria-label', dictionary.arrowPrev);
				toolbar
					.querySelector('.fc-next-button')
					.setAttribute('aria-label', dictionary.arrowNext);

				// Set title Year for screen reader
				toolbarTitle.innerHTML = `<p>${
					view.title
				} <span class="sr-only">${moment.format('YYYY')}</span></p>`;

				// Set full week name

				for (let x = 0; x < weekDays.length; x++) {
					const weekName = Moment().day(x);
					const weekText = document.createElement('span');
					weekText.classList.add('sr-only');
					weekText.innerHTML = `${weekName.format('dddd')}`;

					weekDays[x].setAttribute('scope', 'col');

					weekDays[x].appendChild(weekText);
				}
			},

			eventRender: (e, el, view) => {
				const eventDay = e.start.format('YYYY-MM-DD');
				const today = Moment().format('YYYY-MM-DD');
				const allDays = view.el[0].querySelectorAll('.fc-day-top');
				const dictionary = JSON.parse(this.dom.dictionary);

				// Show only if the date is today or newer
				if (eventDay >= today) {
					for (let x = 0; x < allDays.length; x++) {
						const currentDate = Moment(allDays[x].dataset.date);
						const dayDate = currentDate.format('YYYY-MM-DD');
						const day = allDays[x].children[0];

						if (eventDay == dayDate) {
							day.classList.add('has-event');
							day.setAttribute('role', 'button');
							day.setAttribute('tabIndex', '0');
							day.setAttribute(
								'aria-label',
								`${dictionary.hoursButton} ${currentDate.format(
									'dddd, MMMM D'
								)}`
							);
							day.setAttribute(
								'data-gtm-date-click',
								this.replaceTrackingDateClick(
									this.dom.dateClickTemplate,
									currentDate,
									'gtm'
								)
							);
							day.setAttribute(
								'data-ga4-date-click',
								this.replaceTrackingDateClick(
									this.dom.dateClickTemplateGa4,
									currentDate,
									'ga4'
								)
							);
						}
					}
				} else {
					// Hiding the event
					return false;
				}

				// hide everything for the moment
				return false;
			},

			dayClick: (date, e, view) => {
				// This piece of code handles an issue that occurs when dates are clicked too fast. It seem to take the date from the previous
				// calendar date. When that happens, we need to reset the date object before moving on.
				const elDate = e.target.parentElement.getAttribute('data-date');
				const d = new Date(elDate);
				if (d.getDate() != date._d.getDate()) {
					date._d = d;
					// date = Moment.format(elDate);
				}
				// End of error check
				this.calendarDateSelected(date, e, view.el[0]);

				const gtmDateClick = e.target.getAttribute('data-gtm-date-click');
				const ga4DateClick = e.target.getAttribute('data-ga4-date-click');
				this.pushDataLayerEvents(gtmDateClick);
				this.pushDataLayerEvents(ga4DateClick);
			},

			windowResize: (view) => {
				this.changeAspectRatio();
			},
		});

		this.InitStartDate();
	}

	InitStartDate() {
		let result = Moment().format('YYYY-MM-DD');
		$.ajax({
			type: 'GET',
			url: this.dom.el[0].dataset.startapi,
			data: {
				itemId: this.dom.el[0].dataset.itemid,
				lang: this.dom.el[0].dataset.lang,
				showId: this.dom.el[0].dataset.showid,
			},
			dataType: 'json',
			success: (data) => {
				result = Moment(data);
				$(this.dom.calendar).fullCalendar('gotoDate', result);
			},
			error(xhr, status, error) {
				console.log(error);
			},
		});
	}

	calendarDateSelected(date, event, view) {
		event.preventDefault();

		const el = event.target;
		const parentWeek = this.parentUpTo(el, 'fc-week');
		const allWeeks = view.querySelectorAll('.week-selected');
		const allEventSelected = view.querySelectorAll('.event-selected');

		if (el.classList.contains('has-event')) {
			// Reset the template object on day click
			this.obj.button = [];
			this.obj.price = [];
			this.dayClicked = date;

			el.classList.toggle('event-selected');

			for (let x = 0; x < allEventSelected.length; x++) {
				allEventSelected[x].classList.remove('event-selected');
			}
			this.eventPanel(date, event, view);

			if (el.classList.contains('event-selected')) {
				parentWeek.classList.add('week-selected');

				for (let x = 0; x < allWeeks.length; x++) {
					if (allWeeks[x] != parentWeek) {
						allWeeks[x].classList.remove('week-selected');
					}
				}
			} else {
				parentWeek.classList.remove('week-selected');
				this.eventPanelRemove(view);
			}
		}
	}

	setPriceRangeFormat(priceRange) {
		if (!isNaN(priceRange.min_price)) {
			priceRange.min_price = this.formatDecimal(priceRange.min_price);
		}

		if (!isNaN(priceRange.max_price)) {
			priceRange.max_price = this.formatDecimal(priceRange.max_price);
		}
	}

	formatDecimal(strVal) {
		const fVal = parseFloat(strVal);
		return Math.floor(fVal).toString() != strVal
			? fVal.toFixed(2).toString()
			: strVal;
	}

	eventPanel(date, event, view) {
		const eventPanel = document.createElement('div');
		const parentWeek = this.parentUpTo(event.target, 'fc-week');

		this.eventPanelRemove(view);

		this.insertAfter(eventPanel, parentWeek);
		eventPanel.classList.add('event-panel');

		$(this.dom.calendar).fullCalendar('clientEvents', (event) => {
			if (event.start.format('YYYY-MM-DD') == date.format('YYYY-MM-DD')) {
				const timeFormat =
					event.start.minutes() == 0
						? this.dom.sharpTimeFormatInfo
						: this.dom.timeFormatInfo;
				this.obj.button.push({
					title: event.start.format(timeFormat),
					showHours: event.start.format('h:mm'),
					isoTime: event.start,
					periodOfTime: event.start.format('a') == 'pm',
					timeClick: this.replaceTrackingTimeClick(
						this.dom.timeClickTemplate,
						event,
						'gtm'
					),
					timeClickGa4: this.replaceTrackingTimeClick(
						this.dom.timeClickTemplateGa4,
						event,
						'ga4'
					),
				});

				this.setPriceRangeFormat(event.price_range);

				this.obj.price.push({
					priceRange: event.price_range != null,
					url: event.url,
					urlMobile: event.url_mobile || event.url,
					priceMin:
						event.price_range != null ? event.price_range.min_price : '',
					priceMax:
						event.price_range != null ? event.price_range.max_price : '',
					showDate: event.start.format('dddd, MMMM DD'),
					showHours: event.start.format('h:mm'),
					isoTime: event.start,
					periodOfTime: event.start.format('a') == 'pm',
					buyClick: this.replaceTrackingBuyClick(
						this.dom.buyClickTemplate,
						event,
						'gtm'
					),
					buyClickMobile: this.replaceTrackingBuyClick(
						this.dom.buyClickTemplate,
						event,
						'gtm',
						true
					),
					buyClickGa4: this.replaceTrackingBuyClick(
						this.dom.buyClickTemplateGa4,
						event,
						'ga4'
					),
					buyClickMobileGa4: this.replaceTrackingBuyClick(
						this.dom.buyClickTemplateGa4,
						event,
						'ga4',
						true
					),
				});
			}
		});

		this.obj.button = _.orderBy(this.obj.button, ['isoTime'], ['asc']);
		this.obj.price = _.orderBy(this.obj.price, ['isoTime'], ['asc']);

		Mustache.parse(this.dom.eventTemplate);
		eventPanel.innerHTML = Mustache.render(this.dom.eventTemplate, {
			button: this.obj.button,
		});
		Mustache.parse(this.dom.Template);
		eventPanel.querySelector('.event-panel__price').innerHTML = Mustache.render(
			this.dom.priceTemplate,
			{
				price: [],
			}
		);

		this.eventPanelFocus();

		// Selecting the time by default if there's only one time slot available.
		const count = document.querySelector('.event-panel__btn-list')
			.childElementCount;

		if (count === 1) {
			document.querySelector('.event-panel__btn-list button').click();
		}

		this.modal = new ModuleModal(this.dom.el);
		this.modal.init();

		this.gtm = new ModuleGtm(this.dom.el);
		this.gtm.init();

		this.ga4 = new ModuleGA4(this.dom.el);
		this.ga4.init();
	}

	eventPanelFocus() {
		const eventPanel = {
			content: this.dom.calendar.querySelector('.event-panel__content'),
			close: this.dom.calendar.querySelector('.js-panel-close'),
		};

		eventPanel.content.focus();

		eventPanel.content.addEventListener('keydown', (e) => {
			if (e.keyCode == this.dom.keyboard.tab) {
				if (e.shiftKey) {
					/* shift + tab */
					if (document.activeElement == eventPanel.content) {
						eventPanel.close.focus();
						e.preventDefault();
					}
				} else if (document.activeElement == eventPanel.close) {
					eventPanel.content.focus();
					e.preventDefault();
				}
			}

			if (e.keyCode == this.dom.keyboard.escape) {
				this.eventPanelClose();
			}
		});
	}

	eventPanelClose() {
		const eventSelected = this.dom.calendar.querySelector('.event-selected');
		const allWeeks = this.dom.calendar.querySelectorAll('.week-selected');

		for (let x = 0; x < allWeeks.length; x++) {
			allWeeks[x].classList.remove('week-selected');
		}

		eventSelected.focus();
		eventSelected.classList.remove('event-selected');
		this.eventPanelRemove(this.dom.calendar);
	}

	eventPanelRemove(element) {
		const allEventInfo = element.querySelectorAll('.event-panel');

		for (let x = 0; x < allEventInfo.length; x++) {
			allEventInfo[x].parentNode.removeChild(allEventInfo[x]);
		}
	}

	eventPanelTimeChoosed() {
		const thisModule = this;

		function setTemplate(template, obj) {
			return (template.innerHTML = Mustache.render(
				thisModule.dom.priceTemplate,
				obj
			));
		}

		document.addEventListener('click', (e) => {
			const el = e.target;

			if (el.classList.contains('js-event')) {
				if (el.classList.contains('event-panel__buy')) {
					const gtmBuyClick = e.target.getAttribute('data-gtm-buy-click');
					const ga4BuyClick = e.target.getAttribute('data-ga4-buy-click');
					this.pushDataLayerEvents(gtmBuyClick);
					this.pushDataLayerEvents(ga4BuyClick);
				} else {
					const button = this.dom.calendar.querySelectorAll('.js-event');
					const eventPanelPrice = this.dom.calendar.querySelector(
						'.event-panel__price'
					);

					const gtmTimeClick = e.target.getAttribute('data-gtm-time-click');
					const ga4TimeClick = e.target.getAttribute('data-ga4-time-click');
					this.pushDataLayerEvents(gtmTimeClick);
					this.pushDataLayerEvents(ga4TimeClick);

					if (el.classList.contains('time-selected')) {
						el.classList.remove('time-selected');
						setTemplate(eventPanelPrice, { price: [] });
					} else {
						for (let x = 0; x < button.length; x++) {
							button[x].classList.remove('time-selected');
						}

						el.classList.add('time-selected');

						for (let x = 0; x < this.obj.price.length; x++) {
							if (button[x].classList.contains('time-selected')) {
								setTemplate(eventPanelPrice, {
									price: this.obj.price[x],
								});
							}
						}
					}
				}
			}
		});
	}

	changeAspectRatio() {
		const windowWidth = this.dom.w.innerWidth;
		const { calendar } = this.dom;

		function newRatio(ratio) {
			return $(calendar).fullCalendar('option', 'aspectRatio', ratio);
		}

		if (windowWidth >= this.dom.breakpoint.sm) {
			newRatio(1.8);
		} else if (windowWidth >= this.dom.breakpoint.xs) {
			newRatio(1.8);
		} else {
			newRatio(1.2);
		}
	}

	parentUpTo(el, cls) {
		while ((el = el.parentElement) && !el.classList.contains(cls));
		return el;
	}

	insertAfter(newNode, referenceNode) {
		referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
	}

	pushDataLayerEvents(events) {
		if (events) {
			try {
				const parsedEvents = JSON.parse(events);
				for (let i = 0; i < parsedEvents.length; i++) {
					window.dataLayer.push(parsedEvents[i]);
				}
			} catch (e) {
				console.warn('[ModuleCalendar] - Error parsing GTM events');
			}
		}
	}

	replaceTrackingBuyClick(template, event, gaType = 'gtm', isMobile = false) {
		if (!template) return;
		return template
			.replaceAll(
				GTM_DAYS[gaType],
				Moment(event.start).endOf('day').diff(Moment().startOf('day'), 'days')
			)
			.replaceAll(GTM_DATE[gaType], event.start.format('YYYYMMDD'))
			.replaceAll(GTM_TIME[gaType], event.start.format('hh:mm a'))
			.replaceAll(GTM_PRICE[gaType], event.price_range.min_price)
			.replaceAll(
				GTM_HOST[gaType],
				this.getHostName(
					isMobile && event.url_mobile ? event.url_mobile : event.url
				)
			)
			.replaceAll(GTM_LINK_TEXT[gaType], this.dom.buyButtonLabel)
			.replaceAll(
				GTM_LINK_URL[gaType],
				isMobile && event.url_mobile ? event.url_mobile : event.url
			)
			.replaceAll(GTM_INTERACTION[gaType], event.interaction_type)
			.replaceAll(GTM_BOOKING_ENGINE[gaType], event.booking_engine);
	}

	replaceTrackingTimeClick(template, event, gaType = 'gtm') {
		if (!template) return;
		return template.replaceAll(GTM_TIME[gaType], event.start.format('hh:mm a'));
	}

	replaceTrackingDateClick(template, event, gaType = 'gtm') {
		if (!template) return;
		return template.replaceAll(GTM_DATE[gaType], event.format('YYYYMMDD'));
	}

	getHostName(url) {
		return url
			? (url.indexOf('//') > -1 ? url.split('/')[2] : url.split('/')[0])
					.split(':')[0]
					.split('?')[0]
			: '';
	}
}
