import Module from './Module';

export default class ModuleDropdown extends Module {
    
    constructor(el) {
        super(el);

        $.extend(this.dom, {
            toggleDropdown:    this.dom.el[0].querySelector('.dropdown-toggle'),
            dropdownMenu:      this.dom.el[0].querySelector('.dropdown-menu'),
            dropdownMenuItems: this.dom.el[0].querySelectorAll('.dropdown-item')
		});
    }

    init() {
        this.bindEvents();
    }

	bindEvents() {
        let thisModule = this;

        function toggleDropdown(menu, menuToggle, target) {
            if(!menu.classList.contains('is-open')) {
                menuToggle.setAttribute('aria-expanded','true')
                menu.classList.toggle('is-open');
                menuToggle.classList.toggle('is-open');

                /*thisModule.openDirection();*/

                /*if (!thisModule.dom.el[0].classList.contains('+language')) {
                    let target = $(thisModule.dom.el[0]);
                    let scrollTarget = 100;

                    if (document.body.clientWidth >= 992 && document.body.clientWidth <= 1199) {
                        scrollTarget = 200;
                    } else if (document.body.clientWidth >= 1200) {
                        scrollTarget = 300;
                    }

                    $('html, body').animate({scrollTop: target.offset().top - scrollTarget}, 500, () => {});
                }*/
            } else {
                menuToggle.setAttribute('aria-expanded','false')
                menu.classList.toggle('is-open');
                menuToggle.classList.toggle('is-open');
            }
        }

        if (this.dom.toggleDropdown != null) {
            this.dom.toggleDropdown.addEventListener('click', (e) => {
                e.preventDefault();
                toggleDropdown(this.dom.dropdownMenu, this.dom.toggleDropdown, e);
            });
    
            this.dom.toggleDropdown.addEventListener('keyup', (e) => {
                e.preventDefault();
    
                if (e.keyCode == this.dom.keyboard.spacebar) {
                    toggleDropdown(this.dom.dropdownMenu, this.dom.toggleDropdown, e);
                }
            });
    
            this.dom.toggleDropdown.addEventListener('keydown', (e) => {
                let isDropdownOpen = this.dom.dropdownMenu.classList.contains('is-open');
    
                if (e.keyCode == this.dom.keyboard.arrowDown) {
                    e.preventDefault(); 
                    
                    if (isDropdownOpen) {
                        this.dom.dropdownMenuItems[0].focus();
                    }
                }
                
                if (e.keyCode == this.dom.keyboard.arrowUp) {
                    e.preventDefault();
    
                    if (isDropdownOpen) {
                        this.dom.dropdownMenuItems[this.dom.dropdownMenuItems.length-1].focus();
                    }
                }

               
            });
        }
        

        [].forEach.call(this.dom.dropdownMenuItems, (element) => {
            
            element.addEventListener('keydown', (e) => {
                let prevEl, nextEl = null;
                let parentPrev = element.parentElement.previousElementSibling;
                let parentNext = element.parentElement.nextElementSibling;

                if (parentPrev) {
                    prevEl = parentPrev.firstElementChild;
                } else {
                    prevEl = thisModule.dom.dropdownMenuItems[thisModule.dom.dropdownMenuItems.length-1];
                }

                if (parentNext) {
                    nextEl = parentNext.firstElementChild;
                } else {
                    nextEl = thisModule.dom.dropdownMenuItems[0];
                }

                if (event.keyCode == thisModule.dom.keyboard.arrowUp) {
                    thisModule.changeFocus(e, prevEl);
                }

                if (event.keyCode == thisModule.dom.keyboard.arrowDown) {
                    thisModule.changeFocus(e, nextEl);
                }
            });
        });

        document.addEventListener('click', (e) => {
            if (thisModule.dom.dropdownMenu.classList.contains('is-open')) {
                if (!e.target.classList.contains('dropdown-item') && !e.target.classList.contains('dropdown-toggle')) {
                    thisModule.dom.toggleDropdown.setAttribute('aria-expanded','false');
                    thisModule.dom.dropdownMenu.classList.remove('is-open');
                    thisModule.dom.toggleDropdown.classList.remove('is-open');
                }
            }
        });

        document.addEventListener('keyup', (e) => {
            if (thisModule.dom.dropdownMenu.classList.contains('is-open')) {
                if (!e.target.classList.contains('dropdown-item') && !e.target.classList.contains('dropdown-toggle')) {
                    thisModule.dom.toggleDropdown.setAttribute('aria-expanded','false');
                    thisModule.dom.dropdownMenu.classList.remove('is-open');
                    thisModule.dom.toggleDropdown.classList.remove('is-open');
                }
            }
        });

        if (this.dom.toggleDropdown != null) { 
            this.dom.toggleDropdown.addEventListener('keyup', (e) => {
                e.preventDefault();
            });
        }
    }
    
    changeFocus(event, target) {    
        event.preventDefault();

        return target ? target.focus() : this.dom.toggleDropdown.focus();
    }

    openDirection() {
        if (window.innerWidth <= 767) {
            this.dom.dropdownMenu.classList.remove('open-bottom');
            this.dom.dropdownMenu.classList.add('open-top');

            this.dom.toggleDropdown.classList.remove('open-bottom');
            this.dom.toggleDropdown.classList.add('open-top');
        } else {
            this.dom.dropdownMenu.classList.remove('open-top');
            this.dom.dropdownMenu.classList.add('open-bottom');
        }
    } 
}
