import View from "./View";

export default class ViewHome extends View {
    constructor(el) {
        super(el);
    }

    init() {
        super.init();

        let urlString = location.search.split('?')[1],
            urlSplit = urlString.split("&");

        [].forEach.call(urlSplit, (element, i) => {
            let utm = element.split("=")[0],
                value = element.split("=")[1],
                input = document.createElement("input"),
                form = document.querySelector("form");

            input.type = "hidden";
            input.name = utm;
            input.id = utm;
            input.value = value;

            form.insertBefore(input, form.querySelector(".form__field-msg"));

        });
    }
}
